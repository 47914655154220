import React, { useContext, useState } from "react";
import AuthContext from "../../../context/AuthContext";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
  CreateTimeEntry,
  GetDropDownTasks,
  GetDropEmployees,
} from "../APIs/AdminAPIs";
import { useEffect } from "react";
import { DateCellEditor } from "ag-grid-community";
function formatDuration(durationInSeconds) {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = durationInSeconds % 60;
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
}

export default function CreateTimeModal({
  open,
  close,
  onTimeEntrySaved,
  taskId,
  selectedEmployee,
}) {
  const { contextData, setUserData } = useContext(AuthContext);
  const { authTokens } = contextData;
  const [formData, setFormData] = useState({
    start_date: null,
    end_date: null,
    user: selectedEmployee?.user?.id,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [tasks, setTasks] = useState([]);

  const [formErrors, setFormErrors] = useState({
    start_date: null,
    end_date: null,
    user: null,
  });
  const [employees, setEmployees] = useState([]);
  //console.log("selectedEmployee in create task modal",selectedEmployee);

  useEffect(() => {
    fetchEmployees();
  }, []);
  const fetchEmployees = async () => {
    try {
      const employeesResponse = await GetDropEmployees(authTokens);
      // Filter out employees with admin roles
      const filteredEmployees = employeesResponse.data;

      setEmployees(filteredEmployees);
    } catch (error) {
      console.error("Error fetching employees and projects", error);
    }
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    // Validate start_date
    if (!formData.start_date) {
      errors.start_date = "This field is required.";
      isValid = false;
    }

    // Validate end_date
    if (!formData.end_date) {
      errors.end_date = "This field is required.";
      isValid = false;
    }

    // Validate user
    if (!formData.user) {
      errors.user = "This field is required.";
      isValid = false;
    }
    if (
      isValid &&
      dayjs(formData.start_date).isAfter(dayjs(formData.end_date))
    ) {
      // errors.start_date = "Start date cannot be after end date.";
      errors.end_date = "End date cannot be before start date.";
      isValid = false;
    }

    // Check if duration is more than 24 hours
    const durationInSeconds = dayjs(formData.end_date).diff(
      dayjs(formData.start_date),
      "seconds"
    );
    const durationInHours = durationInSeconds / 3600;

    if (isValid && (durationInHours > 24 || durationInHours < 0)) {
      // errors.start_date = "Invalid duration. Ensure start and end dates are within 24 hours.";
      errors.end_date =
        "Invalid duration. Ensure start and end dates are within 24 hours.";
      isValid = false;
    }

    setFormErrors(errors);

    return isValid;
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    try {
      const response = await GetDropDownTasks(authTokens);
      setTasks(response.data);
      //console.log(tasks);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  const handleSaveChanges = async () => {
    if (!validateForm()) return;
    setIsLoading(true);
    // Calculate duration
    const start = dayjs(formData.start_date);
    const end = dayjs(formData.end_date);
    const duration = end.diff(start, "seconds");
    const formattedDuration = formatDuration(duration);
    try {
      const response = await CreateTimeEntry(authTokens, {
        start_time: formData.start_date,
        end_time: formData.end_date,
        duration: formattedDuration,
        task: taskId,
        user: formData.user,
      });

      if (response.status === 201) {
        //console.log("Entry created successfully", response.data);
        close();
        setFormData({
          start_date: null,
          end_date: null,
          task: "",
          user: "",
        });
        setIsLoading(false);
        if (onTimeEntrySaved) {
          onTimeEntrySaved();
        }
      } else if (response.status !== 201) {
        // Handle validation errors
        const errors = response.data;
        setFormErrors({
          start_date: errors.start_date ? errors.start_date[0] : null,
          end_date: errors.end_date ? errors.end_date[0] : null,
          task: errors.task ? errors.task[0] : null,
          user: errors.user ? errors.user[0] : null,
        });
        setIsLoading(false);
      } else {
        console.error("Error creating Entry", response.data);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error creating Entry", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    //console.log(e);

    if (name === "user_id") {
      setFormData({ ...formData, user: { id: value } });
    }
    //   else if (name === "start_date" || name === "end_date") {
    //     const dateValue = new Date(value);
    //     //console.log(`After Input Change - ${name}: ${dateValue}`);
    //     setFormData((prev) => ({ ...prev, [name]: dateValue }));
    //     setFormErrors({ ...formErrors, [name]: null });
    //     // Calculate duration if both start_date and end_date are set
    //     if (name === "start_date" && formData.end_date) {
    //       // Calculate duration
    //       const start = dayjs(dateValue);
    //       const end = dayjs(formData.end_date);
    //       const duration = end.diff(start, "seconds");
    //       const formattedDuration = formatDuration(duration);
    //       setFormData({ ...formData, duration: formattedDuration });
    //     }
    //   }
    else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleStartDteInput = (date) => {
    setFormData({ ...formData, start_date: new Date(date) });
  };
  const handleEndDateInput = (date) => {
    setFormData({ ...formData, end_date: new Date(date) });
  };

  return (
    <>
      <Modal show={open} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Add a TimeEntry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="startDate">
              <Form.Label>Start Time</Form.Label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateTimePicker"]}>
                  <DateTimePicker
                    value={formData.start_date}
                    onChange={handleStartDteInput}
                  />
                </DemoContainer>
              </LocalizationProvider>
              {formErrors.start_date && (
                <Form.Text className="text-danger">
                  {formErrors.start_date}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="endDate">
              <Form.Label>End Time</Form.Label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateTimePicker"]}>
                  <DateTimePicker
                    value={formData.end_date}
                    onChange={handleEndDateInput}
                  />
                </DemoContainer>
              </LocalizationProvider>
              {formErrors.end_date && (
                <Form.Text className="text-danger">
                  {formErrors.end_date}
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="EmployeeSelect">
              <Form.Label>Current Employee</Form.Label>
              <Form.Control
                as="select"
                name="user"
                onChange={handleInputChange}
                value={formData.user} // Use only the id
                disabled
              >
                <option disabled value="">
                  Current Employee
                </option>
                {Object.values(employees)?.map((employee) => (
                  <option key={employee.id} value={employee.id}>
                    {" "}
                    {/* Use employee.id instead of employee.email */}
                    {`${employee.email} (${employee.role})`}
                  </option>
                ))}
              </Form.Control>
              {formErrors.user && (
                <Form.Text className="text-danger">{formErrors.user}</Form.Text>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isLoading}
            variant="primary"
            onClick={handleSaveChanges}
          >
            {isLoading ? <div className="loading-spinner" /> : "Save Changes"}
          </Button>
          <Button className="btn-danger" onClick={close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
