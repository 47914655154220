import React from "react";
import ReactPaginate from "react-paginate";
import "../../Styles/paginationStyles.css";
import { Pagination } from "react-bootstrap";

// export default function BasePagination({ handlePageClick, pageCount, setCurrentPage, className }) {
//     const handelNumberPage = (number)=>{
//         setCurrentPage(~~number)
//     }
//   return (
//     <div className={` d-flex justify-content-center align-content-center ${className}`}>
//       <Pagination className={` rounded-5  `} >
//         <Pagination.First key={1} onClick={() => handelNumberPage(1)} />
//         {Array?.from({ length: pageCount  }, (_, index) => index + 1)?.map(
//           (pageNumber) => (
//             <Pagination.Item
//               key={pageNumber}
//               onClick={() => handelNumberPage(pageNumber)}
//             >
//               {pageNumber}
//             </Pagination.Item>
//           )
//         )}
//         <Pagination.Last
//           key={pageCount}
//           onClick={() => handelNumberPage(pageCount)}
//         />
//       </Pagination>
//     </div>
//   );
// }
export default function BasePagination({
  handlePageClick,
  pageCount,
  setCurrentPage,
  className,
  currentPage,
}) {
  const handelNumberPage = (number) => {
    setCurrentPage(~~number);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalPages = pageCount;
    const maxPagesToShow = 5; // Adjust as needed

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <Pagination.Item
            key={i}
            // active={currentPage === i}
            onClick={() => handelNumberPage(i)}
          >
            {i}
          </Pagination.Item>
        );
      }
    } else {
      // Add ellipsis for start
      // pageNumbers.push(<Pagination.Ellipsis key="start" disabled />);

      // Determine range of pages to display around the current page
      const startPage = Math.max(currentPage - 2, 1);
      const endPage = Math.min(currentPage + 2, totalPages);

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <Pagination.Item
            key={i}
            // active={currentPage === i}
            onClick={() => handelNumberPage(i)}
          >
            {i}
          </Pagination.Item>
        );
      }

      // Add ellipsis for end
      pageNumbers.push(<Pagination.Ellipsis key="end" disabled />);
    }

    return pageNumbers;
  };

  return (
    <div
      className={`d-flex justify-content-center align-content-center ${className}`}
    >
      <Pagination className="rounded-5">
        <Pagination.First key={1} onClick={() => handelNumberPage(1)} />
        {renderPageNumbers()}
        <Pagination.Last
          key={pageCount}
          onClick={() => handelNumberPage(pageCount)}
        />
      </Pagination>
    </div>
  );
}
