import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import HeaderDiv from "../../utils/Components/HeaderDiv";
import BasePagination from "../../utils/Components/BasePagination";
import CustomTaskCard from "../../utils/Cards/CustomTaskCard";
import { capitalizeEveryName } from "../../utils/Cards/CustomEmployeeCard";
import colors from '../../../resources/colors'
import ExportToCSVBtn from "../../utils/Components/ExportToCSVBtn";
  
export default function EmplyeeTasks() {
  const { state } = useLocation();
  const [allTasks, setAllTasks] = useState(state.tasks);
  const [tasks, setTasks] = useState(state.tasks);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(
    allTasks?.length % 9 > 0 ? allTasks.length / 9 + 1 : allTasks.length / 9
  );
  useEffect(() => {
    setTasks(allTasks.slice((currentPage - 1) * 9, currentPage * 9));
  }, [currentPage]);

  return tasks?.length > 0 ? (
    <div className="d-flex" style={{ height: "100vh" }}>
  
    <div className=" container">
      <HeaderDiv
        title={`${capitalizeEveryName(state?.full_name)} - Tasks`}
      ></HeaderDiv>
      <div className="container d-flex justify-content-center align-content-between flex-wrap mt-3 mx-0">
        {tasks?.map((task, index) => {
          return (
            <div key={index}>
              <CustomTaskCard
                title={task.name}
                description={task.description}
                date={task.created_at}
                userFullName={task.user.full_name}
                total_duration={task.total_duration}
                projectName={task.project.name}
              ></CustomTaskCard>
            </div>
          );
        })}
      </div>
      {totalPages>1?<div style={{ backgroundColor: colors.bg_body, margin: "0px" }}>
          <BasePagination
            className={"m-0 p-0"}
            pageCount={totalPages}
            setCurrentPage={setCurrentPage}
          />
        </div>:null}
      </div>
    </div>
  ) : (
    <div className="col-9 w-75 container">
      <HeaderDiv title={`No Tasks Available`}></HeaderDiv>
    </div>
  );
}
