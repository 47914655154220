import React from "react";
import Card from "react-bootstrap/Card";
import dayjs, { format } from "dayjs";
export default function CustomTaskCard({
  title,
  description,
  date,
  onEditClick,
  onDeleteClick,
  onAssignClick,
  onViewClick,
  isAdmin,
  userFullName,
  total_duration,
  projectName,
}) {
  const formatSeconds = (s) => new Date(s * 1000).toISOString().substr(11, 8);

  const capitalizeFirstLowercaseRest = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const parseDuration = (duration) => {
    let days = 0;
    let [timePart, millisecondsPart] = duration.split(".");
    let [hours, minutes, seconds] = timePart.split(":").map(Number);

    if (duration.includes(" ")) {
      const [daysStr, timeStr] = duration.split(" ");
      days = parseInt(daysStr);
      [hours, minutes, seconds] = timeStr.split(":").map(Number);
    }

    const totalSeconds = days * 86400 + hours * 3600 + minutes * 60 + seconds;
    return totalSeconds;
  };

  const formatDuration = (seconds) => {
    const padZero = (number) => (number < 10 ? `0${number}` : `${number}`);
    const days = Math.floor(seconds / 86400);
    const remainingSeconds = seconds % 86400;
    const hours = Math.floor(remainingSeconds / 3600);
    const remainingSecondsAfterHours = remainingSeconds % 3600;
    const minutes = Math.floor(remainingSecondsAfterHours / 60);
    const finalSeconds = Math.floor(remainingSecondsAfterHours % 60);
    return `${padZero(days)}:${padZero(hours)}:${padZero(minutes)}:${padZero(
      finalSeconds
    )}`;
  };
  return (
    <Card style={{ width: "18rem" }} className=" shadow m-1 mx-4">
      <Card.Body>
        <Card.Title>{capitalizeFirstLowercaseRest(title)}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          {userFullName}
        </Card.Subtitle>
        <Card.Subtitle className="mb-2 text-danger">
          {projectName}
        </Card.Subtitle>
        <Card.Subtitle className="mb-2 text-muted">
          {dayjs(date).format("DD-MM-YY")}
        </Card.Subtitle>
        <Card.Text
          style={{
            maxHeight: "2rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
            lineHeight: "1rem",
            whiteSpace: "nowrap",
          }}
        >
          {" "}
          {description}
        </Card.Text>
        <Card.Footer>
          {"Total Time: " +
            (total_duration
              ? formatDuration(parseDuration(total_duration))
              : "00:00:00")}{" "}
        </Card.Footer>
        {isAdmin ? (
          <>
            <button className="btn" onClick={onAssignClick}>
              <i class="bi bi-person-plus" style={{ color: "blue" }}></i>
            </button>
            <button className="btn" onClick={onViewClick}>
              <i class="bi bi-eye" style={{ color: "black" }}></i>
            </button>
            <button className="btn " onClick={onEditClick}>
              <i class="bi bi-pen " style={{ color: "#FABD22" }}></i>{" "}
            </button>
            <button className="btn" onClick={onDeleteClick}>
              <i class="bi bi-trash3" style={{ color: "red" }}></i>
            </button>
          </>
        ) : null}
      </Card.Body>
    </Card>
  );
}
