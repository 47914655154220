import axios from "axios";
import { generalURL } from "../../utils/APIs/generalURL";

let base = generalURL;
let admin_url = `${base}/admin-role`; //

//--------------------------------------Tasks APIs-----------------------------------------------------------------------------/
export const GetAllTasks = async (authTokens, currentPage, pageSize) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    const response = await axios.get(
      `${admin_url}/all-tasks/?page=${currentPage}&pageSize=${pageSize}`,
      config
    );
    return response;
  } catch (error) {
    console.error("Error getting tasks", error);
    return error.response;
  }
};
export const GetDropDownTasks = async (authTokens) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    const response = await axios.get(`${admin_url}/admin-get-task/`, config);
    return response;
  } catch (error) {
    console.error("Error getting tasks", error);
    return error.response;
  }
};
export const GetTasksById = async (
  authTokens,
  taskId,
  currentPage,
  pageSize
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    const response = await axios.get(
      `${admin_url}/all-tasks/${taskId}?page=${currentPage}&pageSize=${pageSize}`,
      config
    );
    return response;
  } catch (error) {
    console.error("Error getting task by id", error);
    return error.response;
  }
};
export const GetEntriesById = async (
  authTokens,
  timeId,
  currentPage,
  pageSize
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    const response = await axios.get(
      `${admin_url}/time-entries/${timeId}?page=${currentPage}&pageSize=${pageSize}`,
      config
    );
    return response;
  } catch (error) {
    console.error("Error getting task by id", error);
    return error.response;
  }
};

export const CreateTimeEntry = async (authTokens, formData) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    const response = await axios.post(
      `${admin_url}/admin-create-entry/`,
      formData,
      config
    );
    return response;
  } catch (error) {
    console.error("Error Creating Entry", error);
    return error.response;
  }
};

export const UpdateTimeEntry = async (authTokens, timeId, formData) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    const response = await axios.patch(
      `${admin_url}/admin-update-entry/${timeId}/`,
      formData,
      config
    );
    return response;
  } catch (error) {
    console.error("Error Creating Entry", error);
    return error.response;
  }
};
export const DeleteEntry = async (authTokens, taskId) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    const response = await axios.delete(
      `${admin_url}/time-entries/${taskId}`,
      config
    );
    return response;
  } catch (error) {
    console.error("Error Deleting entry", error);
    return error.response;
  }
};

export const GetAdminGroup = async (authTokens) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    const response = await axios.get(`${admin_url}/all-groups/`, config);
    return response;
  } catch (error) {
    console.error("Error getting group", error);
    return error.response;
  }
};
export const CreateGroup = async (authTokens, formData) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    const response = await axios.post(
      `${admin_url}/all-groups/`,
      formData,
      config
    );
    return response;
  } catch (error) {
    console.error("Error Creating group", error);
    return error.response;
  }
};
export const CreateTask = async (authTokens, formData) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    const response = await axios.post(
      `${admin_url}/admin-create-task/`,
      formData,
      config
    );
    return response;
  } catch (error) {
    console.error("Error Creating task", error);
    return error.response;
  }
};
export const EditTask = async (authTokens, taskId, formData) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    const response = await axios.patch(
      `${admin_url}/all-tasks/${taskId}/`,
      formData,
      config
    );
    return response;
  } catch (error) {
    console.error("Error editing task", error);
    return error.response;
  }
};
export const DeleteTask = async (authTokens, taskId, formData) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    const response = await axios.delete(
      `${admin_url}/all-tasks/${taskId}`,
      // formData,
      config
    );
    return response;
  } catch (error) {
    console.error("Error Deleting task", error);
    return error.response;
  }
};

export const GetDropEmployees = async (authTokens) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    const response = await axios.get(`${admin_url}/admin-users-list/`, config);
    return response;
  } catch (error) {
    console.error("Error getting drop Employees", error);
    return error.response;
  }
};
export const GetDropProjects = async (authTokens) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    const response = await axios.get(
      `${admin_url}/admin-projects-list/`,
      config
    );
    return response;
  } catch (error) {
    console.error("Error getting drop projects", error);
    return error.response;
  }
};

//----------------------------------------------------------------------------------------------------------------------------------/
export const GetTimeEntries = async (
  authTokens,
  taskId,
  currentPage,
  pageSize
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    const response = await axios.get(
      `${admin_url}/time-entries/?task=${taskId}&page=${currentPage}&pageSize=${pageSize}`,
      config
    );
    return response;
  } catch (error) {
    console.error("Error getting time-entries", error);
    return error.response;
  }
};

//---------------------------------------Users Api---------------------------------------------------------------//
// export const GetUsers = async (authTokens, currentPage, pageSize) => {
//   try {
//     const config = {
//       headers: {
//         Authorization: `Bearer ${authTokens.access}`,
//       },
//     };
//     const response = await axios.get(
//       `${admin_url}/all-users/?page=${currentPage}&pageSize=${pageSize}`,
//       config
//     );
//     //console.log(response.data);
//     return response;
//   } catch (error) {
//     console.error("Error getting Users", error);
//     return error.response;
//   }
// };
export const GetUsers = async (
  authTokens,
  currentPage,
  pageSize,
  searchTerm
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };

    const response = await axios.get(
      `${admin_url}/all-users/?page=${currentPage}&pageSize=${pageSize}&searchTerm=${searchTerm}`,
      config
    );

    //console.log(response.data);
    return response;
  } catch (error) {
    console.error("Error getting Users", error);
    return error.response;
  }
};

export const GetUsersWithoutPagination = async (authTokens) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    const response = await axios.get(
      `${admin_url}/get-user-no-pagination/`,
      config
    );
    //console.log(response.data);
    return response;
  } catch (error) {
    console.error("Error getting Users", error);
    return error.response;
  }
};
export const GetUsersById = async (
  authTokens,
  userId,
  currentPage,
  pageSize
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    const response = await axios.get(
      `${admin_url}/all-users/${userId}?page=${currentPage}&pageSize=${pageSize}`,
      config
    );
    return response;
  } catch (error) {
    console.error("Error getting User by id", error);
    return error.response;
  }
};

export const DeleteUser = async (authTokens, userId) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    // The typo was here: usersId should be userId
    const response = await axios.delete(
      `${admin_url}/all-users/${userId}`,
      config
    );
    return response;
  } catch (error) {
    console.error("Error deleting user", error);
    return error.response;
  }
};

export const AdminAddUser = async (authTokens, formData) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    // The typo was here: usersId should be userId
    const response = await axios.post(
      `${admin_url}/create-user/`,
      formData,
      config
    );
    return response;
  } catch (error) {
    console.error("Error creating user", error);
    return error.response;
  }
};
export const AdminAddYubikey = async (authTokens, formData) => {
  //console.log(formData);
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    // The typo was here: usersId should be userId
    const response = await axios.post(
      `${admin_url}/admin-create-yubikey/`,
      formData,
      config
    );
    return response;
  } catch (error) {
    console.error("Error creating Yubikey", error);
    return error.response;
  }
};
export const AdminEditUser = async (authTokens, userId, formData) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };

    const response = await axios.patch(
      `${admin_url}/all-users/${userId}/`,
      formData,
      config
    );
    return response;
  } catch (error) {
    console.error("Error editing user", error);
    return error.response;
  }
};
export const AdminUploadUser = async (authTokens, formData) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };

    const response = await axios.post(
      `${generalURL}/core/upload-user/upload_user/`,
      formData,
      config
    );
    return response;
  } catch (error) {
    //console.log("Erorr Uploading User", error);
    return error.response;
  }
};
