import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../context/AuthContext";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
  UpdateTimeEntry,
  GetDropDownTasks,
  GetDropEmployees,
} from "../APIs/AdminAPIs";

function formatDuration(durationInSeconds) {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = durationInSeconds % 60;
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
}

export default function EditTimeModal({
  open,
  close,
  onTimeEntrySaved,
  taskId,
  initialData,
}) {
  const { contextData, setUserData } = useContext(AuthContext);
  const { authTokens } = contextData;
  const [formData, setFormData] = useState({
    start_date: null,
    end_date: null,
    user: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [tasks, setTasks] = useState([]);

  const [formErrors, setFormErrors] = useState({
    start_date: null,
    end_date: null,
    user: null,
  });
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    fetchEmployees();
  }, []);

  useEffect(() => {
    // Set initial data when the modal is opened for editing
    if (initialData) {
      setFormData({
        start_date: dayjs(initialData.start_time),
        end_date: dayjs(initialData.end_time),
        user: initialData.user?.id,
      });
    }
  }, [initialData]);

  const fetchEmployees = async () => {
    try {
      const employeesResponse = await GetDropEmployees(authTokens);
      const filteredEmployees = employeesResponse.data;

      setEmployees(filteredEmployees);
    } catch (error) {
      console.error("Error fetching employees and projects", error);
    }
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.start_date) {
      errors.start_date = "This field is required.";
      isValid = false;
    }

    if (!formData.end_date) {
      errors.end_date = "This field is required.";
      isValid = false;
    }

    if (!formData.user) {
      errors.user = "This field is required.";
      isValid = false;
    }

    if (
      isValid &&
      dayjs(formData.start_date).isAfter(dayjs(formData.end_date))
    ) {
      errors.end_date = "End date cannot be before start date.";
      isValid = false;
    }

    const durationInSeconds = dayjs(formData.end_date).diff(
      dayjs(formData.start_date),
      "seconds"
    );
    const durationInHours = durationInSeconds / 3600;

    if (isValid && (durationInHours > 24 || durationInHours < 0)) {
      errors.end_date =
        "Invalid duration. Ensure start and end dates are within 24 hours.";
      isValid = false;
    }

    setFormErrors(errors);

    return isValid;
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    try {
      const response = await GetDropDownTasks(authTokens);
      setTasks(response.data);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  const handleEditChanges = async () => {
    if (!validateForm()) return;
    setIsLoading(true);

    const start = dayjs(formData.start_date);
    const end = dayjs(formData.end_date);
    const duration = end.diff(start, "seconds");
    const formattedDuration = formatDuration(duration);

    try {
      const response = await UpdateTimeEntry(authTokens, initialData.id, {
        start_time: formData.start_date,
        end_time: formData.end_date,
        duration: formattedDuration,
        task: taskId,
        user: formData.user,
      });

      if (response.status === 200) {
        //console.log("Entry updated successfully", response.data);
        close();
        setFormData({
          start_date: null,
          end_date: null,
          task: "",
          user: "",
        });
        setIsLoading(false);

        if (onTimeEntrySaved) {
          onTimeEntrySaved();
        }
      } else if (response.status !== 200) {
        const errors = response.data;
        setFormErrors({
          start_date: errors.start_date ? errors.start_date[0] : null,
          end_date: errors.end_date ? errors.end_date[0] : null,
          task: errors.task ? errors.task[0] : null,
          user: errors.user ? errors.user[0] : null,
        });
        setIsLoading(false);
      } else {
        console.error("Error updating Entry", response.data);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error updating Entry", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "user_id") {
      setFormData({ ...formData, user: { id: value } });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleStartDteInput = (date) => {
    setFormData({ ...formData, start_date: new Date(date) });
  };

  const handleEndDateInput = (date) => {
    setFormData({ ...formData, end_date: new Date(date) });
  };

  return (
    <>
      <Modal show={open} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Edit TimeEntry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="startDate">
              <Form.Label>Start Time</Form.Label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateTimePicker"]}>
                  <DateTimePicker
                    value={formData.start_date}
                    onChange={handleStartDteInput}
                  />
                </DemoContainer>
              </LocalizationProvider>
              {formErrors.start_date && (
                <Form.Text className="text-danger">
                  {formErrors.start_date}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="endDate">
              <Form.Label>End Time</Form.Label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateTimePicker"]}>
                  <DateTimePicker
                    value={formData.end_date}
                    onChange={handleEndDateInput}
                  />
                </DemoContainer>
              </LocalizationProvider>
              {formErrors.end_date && (
                <Form.Text className="text-danger">
                  {formErrors.end_date}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="EmployeeSelect">
              <Form.Label>Current Employee</Form.Label>
              <Form.Control
                as="select"
                name="user"
                onChange={handleInputChange}
                value={formData.user}
                disabled
              >
                <option disabled value="">
                  Select an employee
                </option>
                {Object.values(employees)?.map((employee) => (
                  <option key={employee.id} value={employee.id}>
                    {`${employee.email} (${employee.role})`}
                  </option>
                ))}
              </Form.Control>
              {formErrors.user && (
                <Form.Text className="text-danger">{formErrors.user}</Form.Text>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isLoading}
            variant="primary"
            onClick={handleEditChanges}
          >
            {isLoading ? <div className="loading-spinner" /> : "Save Changes"}
          </Button>
          <Button className="btn-danger" onClick={close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
