import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useState } from "react";
import { AdminUploadUser } from "../APIs/AdminAPIs";
import { useContext } from "react";
import AuthContext from "../../../context/AuthContext";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function UserUploadForm({ fetchUploadUsers }) {
  const { contextData } = useContext(AuthContext);
  const { authTokens } = contextData;
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    // //console.log(formData);

    setIsLoading(true);
    try {
      await AdminUploadUser(authTokens, formData);
      alert("File uploaded successfully");
      fetchUploadUsers();
      // const response = await AdminUploadUser(authTokens, formData);
      // //console.log(response)
      // if (response.status === "201") {
      // } else {
      // const data = await response.json();
      // alert(`Error: ${data.detail}`);
      // }
    } catch (error) {
      alert("An error occurred during file upload.");
    }
    setIsLoading(false);
  };
  return (
    <>
      <Button component="label" variant="contained" onChange={handleFileChange}>
        choose file
        <VisuallyHiddenInput type="file" />
      </Button>
      <Button
        variant="contained"
        onClick={handleUpload}
        startIcon={<CloudUploadIcon />}
      >
        Upload file
      </Button>
      {isLoading && (
        <div className="loading-spinner-container">
          <div className="loading-spinner"></div>
        </div>
      )}
    </>
  );
}

// import { useState } from "react";
// const UserUploadForm = () => {
//   const [file, setFile] = useState(null);

//   const handleFileChange = (event) => {
//     setFile(event.target);
//   };
// //console.log(file);
//   const handleUpload = async () => {
//     if (!file) {
//       alert("Please select a file.");
//       return;
//     }

//     const formData = new FormData();
//     formData.append("file", file);

//     try {
//       const response = await fetch(
//         "http://127.0.0.1:8000/core/upload-user/upload_user/",
//         {
//           method: "POST",
//           body: formData,
//         }
//       );

//       if (response.ok) {
//         alert("File uploaded successfully");
//       } else {
//         const data = await response.json();
//         alert(`Error: ${data.detail}`);
//       }
//     } catch (error) {
//       console.error("Error during file upload:", error);
//       alert("An error occurred during file upload.");
//     }
//   };

//   return (
//     <div>
//       <input type="file" onChange={handleFileChange} />
//       <button onClick={handleUpload}>Upload</button>
//     </div>
//   );
// };

// export default UserUploadForm;
