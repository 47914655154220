import axios from "axios";
import { generalURL } from "../../utils/APIs/generalURL";
import { getConfig } from "./getConfig";
let base = generalURL;
let coreUrl = `${base}/api`; //
let hrUrl = `${base}/hr`;

export const getAllEmployees = async () => {
  const config = getConfig();
  try {
    let url = `${coreUrl}/employees-list/`;
    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    console.error("Error fetching employees:", error);
    return error.response;
  }
};

export const getAllProjects = async (page, searchLabel) => {
  const config = getConfig();
  try {
    let url = `${coreUrl}/projects/?page=${page}`;
    if (searchLabel) {
      url += `&name=${searchLabel}`;
    }
    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    console.error("Error fetching projects:", error);
    return error.response;
  }
};

export const addProject = async (obj) => {
  const config = getConfig();

  return axios.post(`${coreUrl}/projects/`, obj, config);

  // try {
  //   const response = await axios.post(`${coreUrl}/projects/`, obj, config);
  //   return response;
  // } catch (error) {
  //   console.error("Error adding project:", error);
  //   return error.response;
  // }
};

export const updateProject = async (id, newProject) => {
  const config = getConfig();

  return axios.patch(`${coreUrl}/projects/${id}/`, newProject, config);

  // try {
  //   const response = await axios.patch(
  //     `${coreUrl}/projects/${id}/`,
  //     newProject,
  //     config
  //   );
  //   return response;
  // } catch (error) {
  //   console.error("Error updating project:", error);
  //   return error.response;
  // }
};

export const deleteProject = async (id) => {
  const config = getConfig();

  try {
    const response = await axios.delete(`${coreUrl}/projects/${id}/`, config);
    return response;
  } catch (error) {
    console.error("Error deleting project:", error);
    return error.response;
  }
};

export const getTimeSheetByUserId = async (userId) => {
  const config = getConfig();
  try {
    let url = `${hrUrl}/get-employee-time-sheet/?user_id=${userId}`;
    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    console.error("Error fetching timesheet:", error);
    return error.response;
  }
};
