import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../context/AuthContext";
import {
  DeleteUser,
  GetAdminGroup,
  GetUsers,
  GetUsersById,
} from "../APIs/AdminAPIs";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { AdminCreateModal } from "./AdminCreateModal";
import "../Tasks/spinner.css";
import { AdminEditModal } from "./AdminEditModal";
import UserUploadForm from "./UserUploadForm";
import ExportToCSVBtn from "../../utils/Components/ExportToCSVBtn";
import { AdminCreateYubikeyModal } from "./AdminCreateYubikeyModal";
import { AdminCreateGroupModal } from "./AdminCreateGroupModal";

export function AdminUser() {
  const [users, setUsers] = useState([]);
  const { contextData } = useContext(AuthContext);
  const { authTokens } = contextData;
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isCreateYubikeyModalOpen, setIsCreateYubikeyModalOpen] =
    useState(false);
  const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [countRows, setCountRows] = useState();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 9,
    page: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [editUser, setEditUser] = useState([]);
  const [currentUserGroup, setCurrentUserGroup] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchUsers();
  }, [paginationModel]);

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const response = await GetUsers(
        authTokens,
        paginationModel.page + 1,
        paginationModel.pageSize,
        searchTerm
      );

      // Process the data to remove duplicates
      const processedUsers = processUsers(response.data);

      setUsers(processedUsers);
      setCountRows(response.data.length);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching Users:", error);
    }
  };

  useEffect(() => {
    fetchAdminGroup();
  }, []);
  const fetchAdminGroup = async () => {
    try {
      const response = await GetAdminGroup(authTokens);
      setCurrentUserGroup(response.data.results);
      //console.log(response.data.results);
      // if (!currentUserGroup || currentUserGroup.length === 0) {
      //       // Fetch admin group only if it hasn't been fetched before
      //       fetchAdminGroup();
      //     }
    } catch (error) {
      console.error("Error fetching admin group:", error);
    }
  };

  // useEffect(() => {
  //   const fetchAdminGroup = async () => {
  //     try {
  //       const response = await GetAdminGroup(authTokens);
  //       setCurrentUserGroup(response.data.results);
  //       //console.log(response.data.results);

  //     } catch (error) {
  //       console.error("Error fetching admin group:", error);
  //     }
  //   };

  //   if (!currentUserGroup || currentUserGroup.length === 0) {
  //     // Fetch admin group only if it hasn't been fetched before
  //     fetchAdminGroup();
  //   }
  // }, [authTokens, currentUserGroup]);

  const processUsers = (originalUsers) => {
    // Create a map to store users based on their IDs
    const userMap = new Map();

    // Iterate through the original users
    originalUsers.forEach((user) => {
      // If the user is already in the map, update their information
      if (userMap.has(user.id)) {
        const existingUser = userMap.get(user.id);
        existingUser.custom_group = [
          ...existingUser.custom_group,
          ...user.custom_group,
        ];
      } else {
        // If the user is not in the map, add them
        userMap.set(user.id, { ...user });
      }
    });

    // Convert the map values back to an array
    const processedUsers = Array.from(userMap.values());

    return processedUsers;
  };
  const handleEdit = async (userId) => {
    try {
      const response = await GetUsersById(
        authTokens,
        userId,
        paginationModel.page + 1,
        paginationModel.pageSize
      );
      setEditUser(response.data);
      //console.log("user by id", response.data); // Assuming response.data contains the user data
    } catch (error) {
      console.error("Error fetching user by id:", error);
    }
    setIsEditModalOpen(true);
    setUserId(userId);
  };

  const handleCreateModalClose = () => {
    setIsCreateModalOpen(false);
  };

  const handleCreateYubikeyModalClose = () => {
    setIsCreateYubikeyModalOpen(false);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
    setEditUser([]);
  };

  const handleDelete = async (userId, userEmail) => {
    const isConfirmed = window.confirm(
      `Are you sure you want to delete user "${userEmail}"?`
    );

    // If user confirms, proceed with deletion
    if (isConfirmed) {
      setIsLoading(true);

      try {
        // Perform the deletion
        await DeleteUser(authTokens, userId);

        // Remove the deleted user from the local state
        const updatedUsers = users.filter((user) => user.id !== userId);
        setUsers(updatedUsers);

        // Check if there are no more users on the current page
        if (updatedUsers.length === 0 && paginationModel.page > 1) {
          setIsLoading(true);
          // If there are no more users on the current page and it's not the first page,
          // go back to the previous page
          const newPage = paginationModel.page - 1;

          // Update the pagination model
          setPaginationModel((prevModel) => ({
            ...prevModel,
            page: newPage,
          }));
        }

        setIsLoading(true);
        // Fetch the updated total count of users without fetching the users themselves
        const totalCountResponse = await GetUsers(
          authTokens,
          paginationModel.page + 1, // Use the current page for total count
          paginationModel.pageSize
        );

        // Update the total count in the pagination model
        setPaginationModel((prevModel) => ({
          ...prevModel,
          rowCount: totalCountResponse.data.count || 0,
        }));
        setIsLoading(false);
      } catch (error) {
        console.error("Error during deletion:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleUserSaved = () => {
    fetchUsers();
    setIsCreateModalOpen(false);
    setIsEditModalOpen(false);
    setIsCreateYubikeyModalOpen(false);
  };

  const handleUserEdited = () => {
    fetchUsers();
  };

  const handleCreateUser = () => {
    setIsCreateModalOpen(true);
    //console.log("Create User clicked");
  };

  const handleCreateYubikey = () => {
    setIsCreateYubikeyModalOpen(true);
    //console.log("create yubikey open");
  };

  const handleCreateGroup = () => {
    setIsCreateGroupModalOpen(true);
    //console.log("Create Group clicked");
  };

  const handleCreateGroupModalClose = () => {
    setIsCreateGroupModalOpen(false);
  };
  const handleGroupSaved = () => {
    setIsCreateGroupModalOpen(false);
    fetchUsers();
    fetchAdminGroup();
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 125,
    },
    {
      field: "username",
      headerName: "Username",
      width: 125,
    },
    {
      field: "first_name",
      headerName: "First Name",
      width: 125,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 125,
    },
    {
      field: "full_name",
      headerName: "Full-Name",
      width: 125,
    },
    {
      field: "email",
      headerName: "Email",
      width: 125,
    },
    {
      field: "role",
      headerName: "Role",
      width: 125,
    },
    {
      field: "yubikey",
      headerName: "Yubikey",
      width: 125,
      valueGetter: (params) => (params.row.yubikey ? params.row.yubikey : null),
    },
    {
      field: "custom_group",
      headerName: "Group",
      width: 125,
      valueGetter: (params) => {
        const { custom_group } = params.row;

        if (!custom_group || custom_group.length === 0) {
          // Handle case where there are no groups
          return null;
        }

        // Extract the group names from the custom_group array
        const groupNames = custom_group
          ?.map((group) => group?.name)
          ?.filter(Boolean);

        // Join the group names into a comma-separated string
        return groupNames.join(", ");
      },
    },

    {
      width: 125,
      disableSorting: true,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div>
          <IconButton onClick={() => handleEdit(params.row.id)} color="primary">
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDelete(params.row.id, params.row.email)}
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const flattenedData = [
    {
      first_name: "eslam",
      last_name: "samir",
      username: "samir",
      email: "employeeName@gmail.com",
      password: "123456",
      role: "EMPLOYEE",
      yubikey: "123",
    },
    {
      first_name: "eslam2",
      last_name: "samir2",
      username: "samir",
      email: "adminName@gmail.com",
      password: "123456",
      role: "ADMIN",
      yubikey: "555",
    },
    {
      first_name: "eslam3",
      last_name: "samir3",
      username: "samir3",
      email: "supervisorName@gmail.com",
      password: "123456",
      role: "SUPERVISOR",
      yubikey: "111",
    },
  ];
  const handleSearchTermChange = (value) => {
    setSearchTerm(value);
    // setPaginationModel((prevModel) => ({
    //   ...prevModel,
    //   page: 0, // Reset to the first page when the search term changes
    // }));
  };
  const handleSearchOnClick = () => {
    fetchUsers();
  };

  const CustomToolbar = () => (
    <div style={{ padding: "10px" }}>
      <GridToolbarContainer>
        <GridToolbarExport />
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />

        <GridToolbarQuickFilter

        // onDragEnter={()=>handleSearchOnClick()}
        />
      </GridToolbarContainer>
      <div className="d-flex flex-sm-row flex-wrap flex-column justify-content-between align-items-start mt-3">
        <div className="d-flex flex-wrap gap-3 mb-3">
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleCreateUser}
          >
            CREATE USER
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleCreateYubikey}
          >
            CREATE YUBIKEY
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleCreateGroup}
          >
            CREATE GROUP
          </Button>
          <UserUploadForm fetchUploadUsers={handleUserEdited} />
          <ExportToCSVBtn
            data={flattenedData}
            titlepop="true"
            nameButton="FILE UPLOAD EXAMPLE"
            fileName={`how to upload file`}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      {isLoading ? (
        <div className="loading-spinner-container">
          <div className="loading-spinner"></div>
        </div>
      ) : (
        <DataGrid
          rows={users || []}
          columns={columns}
          autoHeight={false}
          disableSelectionOnClick
          onSelectionModelChange
          disableRowSelectionOnClick
          slots={{
            toolbar: CustomToolbar,
          }}
          pagination
          rowCount={countRows}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          paginationMode="client"
        />
      )}
      {isCreateModalOpen && (
        <AdminCreateModal
          open={isCreateModalOpen}
          close={handleCreateModalClose}
          onUserSaved={handleUserSaved}
          users={users}
          group={currentUserGroup}
        />
      )}
      {isCreateYubikeyModalOpen && (
        <AdminCreateYubikeyModal
          open={isCreateYubikeyModalOpen}
          close={handleCreateYubikeyModalClose}
          onUserSaved={handleUserSaved}
        />
      )}
      {isCreateYubikeyModalOpen && (
        <AdminCreateYubikeyModal
          open={isCreateYubikeyModalOpen}
          close={handleCreateYubikeyModalClose}
          onUserSaved={handleUserSaved}
        />
      )}
      {isEditModalOpen && (
        <AdminEditModal
          open={isEditModalOpen}
          close={handleEditModalClose}
          onUserEdited={handleUserEdited}
          userId={userId}
          user={editUser}
          data={users}
          group={currentUserGroup}
        />
      )}
      {isCreateGroupModalOpen && (
        <AdminCreateGroupModal
          open={isCreateGroupModalOpen}
          close={handleCreateGroupModalClose}
          onGroupSaved={handleGroupSaved}
        />
      )}
    </div>
  );
}
