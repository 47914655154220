import React, { useContext, useState } from "react";
import "../../resources/font.css";
import log from "../../imgs/Login/login.webp";
import "../../resources/colors.js";
import "../../resources/font.css";
import colors from "../../resources/colors.js";
import "./login.css";
import { Button, Form } from "react-bootstrap";
import AuthContext from "../../context/AuthContext";

export function Login() {
  const { contextData } = useContext(AuthContext);
  const { login } = contextData;
  const [errorMessage, setErrorMessage] = useState("");
  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await login(event);
    if (response.status === 401) {
      setErrorMessage("Invalid username or password");
      alert("Invalid email or password");
    } else if (response.status === 200) {
    } else {
      alert("Login failed");
    }
  };

  return (
    <div
      style={{
        backgroundColor: colors.bg_body,
        width: "100%",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <h4 style={{ margin: "30px", textShadow: "0.2px 0px black" }}>
        Task Tracker
      </h4>
      <div style={{ display: "flex" }} className="secondary-body">
        <img src={log} alt="login-body" className="image-body" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h4 style={{ textShadow: "0.1px 0px black" }}>Welcome Back!</h4>
          <h5>Become more productive with </h5>
          <h5>Task Tracker</h5>
          <div>
            <Form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                margin: "30px",
              }}
            >
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  className="no-outline"
                  type="email"
                  name="email"
                  placeholder="Email"
                  style={{
                    backgroundColor: colors.bg_body,
                    border: "none",
                    borderBottom: "1px solid black",
                    borderRadius: "0px",
                  }}
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Control
                  className="no-outline"
                  type="password"
                  name="password"
                  placeholder="Password"
                  style={{
                    backgroundColor: colors.bg_body,
                    border: "none",
                    marginTop: "10px",
                    borderBottom: "1px solid black",
                    borderRadius: "0",
                    outline: "none",
                  }}
                />
              </Form.Group>

              <Button
                style={{
                  backgroundColor: colors.bg_button,
                  border: "0px",
                  borderRadius: "25px",
                  width: "70%",
                  height: "50px",
                  marginTop: "10px",
                }}
                type="submit"
              >
                <p style={{ fontSize: "25px" }}>Login</p>
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
