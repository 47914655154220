import axios from "axios";
import { generalURL } from "../../utils/APIs/generalURL";
import { getConfig } from "../../utils/APIs/getConfig";
let base = generalURL;
let hr_url = `${base}/hr`;
let coreUrl = `${base}/api`;
let employee_url = `${base}/employees`;

export const getTasks = async (page, projectId, userId) => {
  const config = getConfig();

  try {
    let url = `${coreUrl}/tasks-list/?page=${page}`;
    if (projectId) {
      url += `&project_id=${projectId}`;
    }
    if (userId) {
      url += `&user_id=${userId}`;
    }
    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    console.error("Error fetching tasks:", error);
    return error.response;
  }
};

export const getEmployees = async (page, name) => {
  const config = getConfig();
  try {
    let url = `${coreUrl}/employees-list/?paginated=true&page=${page}`;
    if (name) {
      url += `&name=${name}`;
    }
    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    console.error("Error fetching employees:", error);
    return error.response;
  }
};

export const GetWorkingHrs = async (selectedYear, employeedId) => {
  const config = getConfig();
  let url = `${hr_url}/get-time-sheet-by-year/?year=${selectedYear}`;
  if (employeedId) {
    url += `&user_id=${employeedId}`;
  }
  try {
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    console.error("Error getting timesheet", error);
    return error.response;
  };
};
