const BaseYearSelector = ({ selectedYear, onChange, className }) => {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, index) => currentYear - index);

  return (
    <select value={selectedYear} onChange={onChange} className={className}>
      {years?.map((year) => (
        <option key={year} value={year}>
          {year}
        </option>
      ))}
    </select>
  );
};

export default BaseYearSelector;
