import Table from "react-bootstrap/Table";
// import BaseButton from "./BaseButton";

function BaseTable({ data, title, width, minWidth }) {
  return (
    <div className="m-1">
      <h2 className="text-center p-1">{title}</h2>

      <div className="m-1" style={{ maxWidth: "100%", overflowX: "auto" }}>
        <Table
          hover
          striped
          responsive="xl"
          style={{
            textAlign: "left",
            width: width ? width : "100%",
            minWidth: minWidth ? minWidth : "100%",
          }}
        >
          <thead>
            <tr>
              {data?.headers?.map((item, index) => (
                <th key={index}>{item} </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.rows?.map((row, index) => (
              <tr key={index}>
                {Object?.entries(row)?.map(([key, value]) => (
                  <td key={key}>{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default BaseTable;
