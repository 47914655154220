// import React, { useContext, useEffect, useState } from "react";
// import { ChartAccordion } from "../../utils/Components/ChartAccordion";
// import AuthContext from "../../../context/AuthContext";
// import { useLocation } from "react-router-dom";
// import { GetWorkingHrs } from "../APIs/HRAPIs";
// import HeaderDiv from "../../utils/Components/HeaderDiv";
// import ExportToCSVBtn from "../../utils/Components/ExportToCSVBtn";

// export function HRTimeSheet({ admin }) {
//   const [workingHrs, setWorkingHrs] = useState([]);
//   const { state } = useLocation();
//   //console.log(state);
//   let { selectedYear } = state;
//   let { employeeId } = state;
//   //console.log(employeeId);
//   const flattenedData = [];

//   useEffect(() => {
//     fetchTimeEntries();
//   }, [selectedYear, employeeId]);

//   const fetchTimeEntries = () => {
//     GetWorkingHrs(selectedYear, employeeId)
//       .then((response) => {
//         setWorkingHrs(response.data );
//         //console.log(response.data);
//       })
//       .catch((error) => {
//         console.error("Error getting timesheet", error);
//       });
//   };

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     return date.toDateString();
//   };

//   //console.log(workingHrs);

//   if (employeeId === undefined) {
//     workingHrs?.forEach((entry) => {
//       const { month, time_entries } = entry;
//       time_entries?.forEach((timeEntry) => {
//         const [date, total_duration] = Object.values(timeEntry);
//         flattenedData.push({
//           month,
//           date: formatDate(date),
//           total_duration: total_duration,
//         });
//       });
//     });
//   } else if (employeeId !== undefined && admin === "true") {
//     workingHrs?.forEach((entry) => {
//       const { month, time_entries } = entry;
//       time_entries?.forEach((timeEntry) => {
//         const [date, total_duration, yubikey] = Object.values(timeEntry);
//         flattenedData.push({
//           month,
//           date: formatDate(date),
//           total_duration: total_duration,
//           Yubikey: yubikey,
//         });
//       });
//     });
//   } else {
//     workingHrs?.forEach((entry) => {
//       const { month, time_entries } = entry;
//       time_entries?.forEach((timeEntry) => {
//         const [date, total_duration, yubikey, email] = Object.values(timeEntry);
//         flattenedData.push({
//           month,
//           date: formatDate(date),
//           total_duration: total_duration,
//           Yubikey: yubikey,
//           Email: email,
//         });
//       });
//     });
//   }

//   return (
//     <div className="d-flex row " style={{ width: "75%", padding: "0px" }}>
//       <HeaderDiv title={`${selectedYear} Timesheet`} />
//       <div>
//         <ExportToCSVBtn
//           data={flattenedData}
//           fileName={`${selectedYear} timesheet`}
//         ></ExportToCSVBtn>
//       </div>
//       <div className="mt-3">
//         <ChartAccordion workingHrs={workingHrs} />
//       </div>
//     </div>
//   );
// }

import React, { useContext, useEffect, useState } from "react";
import { ChartAccordion } from "../../utils/Components/ChartAccordion";
import AuthContext from "../../../context/AuthContext";
import { useLocation } from "react-router-dom";
import { GetWorkingHrs } from "../APIs/HRAPIs";
import HeaderDiv from "../../utils/Components/HeaderDiv";
import ExportToCSVBtn from "../../utils/Components/ExportToCSVBtn";

export function HRTimeSheet({ admin }) {
  const [workingHrs, setWorkingHrs] = useState([]);
  const { state } = useLocation();
  const { selectedYear, employeeId } = state || {};
  const flattenedData = [];

  useEffect(() => {
    if (selectedYear !== undefined) {
      fetchTimeEntries();
    }
  }, [selectedYear, employeeId]);

  const fetchTimeEntries = () => {
    GetWorkingHrs(selectedYear, employeeId)
      .then((response) => {
        setWorkingHrs(response.data || []);
        //console.log(response.data);
      })
      .catch((error) => {
        console.error("Error getting timesheet", error);
      });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toDateString();
  };
  const formatDuration = (seconds) => {
    const padZero = (number) => (number < 10 ? `0${number}` : `${number}`);
    const days = Math.floor(seconds / 86400);
    const remainingSeconds = seconds % 86400;
    const hours = Math.floor(remainingSeconds / 3600);
    const remainingSecondsAfterHours = remainingSeconds % 3600;
    const minutes = Math.floor(remainingSecondsAfterHours / 60);
    const finalSeconds = Math.floor(remainingSecondsAfterHours % 60);
    return `${padZero(days)}:${padZero(hours)}:${padZero(minutes)}:${padZero(
      finalSeconds
    )}`;
  };
  const parseDuration = (duration) => {
    let days = 0;
    let [timePart, millisecondsPart] = duration.split(".");
    let [hours, minutes, seconds] = timePart.split(":").map(Number);

    if (duration.includes(" ")) {
      const [daysStr, timeStr] = duration.split(" ");
      days = parseInt(daysStr);
      [hours, minutes, seconds] = timeStr.split(":").map(Number);
    }

    const totalSeconds = days * 86400 + hours * 3600 + minutes * 60 + seconds;
    return totalSeconds;
  };

  if (Array.isArray(workingHrs)) {
    workingHrs.forEach((entry) => {
      const { month, time_entries } = entry || {};
      if (Array.isArray(time_entries)) {
        time_entries.forEach((timeEntry) => {
          const {
            date,
            total_duration,
            email,
            yubikey,
            projects,
            tasks,
            username,
          } = timeEntry || {};
          const dataEntry = {
            month,
            date: formatDate(date),
            total_duration,
            Yubikey: admin === "true" ? yubikey : undefined,
            Email: email,
            Project: projects,
            Task: tasks,
            Username: username,
          };

          if (employeeId === undefined) {
            delete dataEntry.Yubikey;
            delete dataEntry.Email;
          } else if (employeeId !== undefined && admin !== "true") {
            delete dataEntry.Yubikey;
          }

          flattenedData.push(dataEntry);
        });
      }
    });
  }

  return (
    <div className="d-flex row " style={{ width: "75%", padding: "0px" }}>
      <HeaderDiv title={`${selectedYear} Timesheet`} />
      <div>
        <ExportToCSVBtn
          data={flattenedData}
          fileName={`${selectedYear} timesheet`}
        ></ExportToCSVBtn>
      </div>
      <div className="mt-3">
        <ChartAccordion workingHrs={workingHrs} />
      </div>
    </div>
  );
}
