import React, { useContext, useEffect, useState } from "react";
import { GetAssignedProjects } from "../APIs/EmployeeAPIS";
import { EmployeeSideBar } from "../EmployeeSideBar";
import AuthContext from "../../../context/AuthContext";
import CustomCard from "../../utils/Cards/CustomProjectCard";
import BaseSearch from "../../utils/Components/BaseSearch";
import BasePagination from "../../utils/Components/BasePagination";
import HeaderDiv from "../../utils/Components/HeaderDiv";
import { TaskModal } from "../Tasks/TaskModal";
import { useNavigate } from "react-router-dom";

export function Projects() {
  const { contextData } = useContext(AuthContext);
  const { authTokens, user } = contextData;
  const [assignedProjects, setAssignedProjects] = useState([]);
  const [searchLabel, setSearchLabel] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigator = useNavigate();
  const itemsPerPage = 9;
  const onViewClick = (project) => {
    //console.log("onviewclick");
    //console.log(user.user_id);
    navigator(`/employee/project-tasks/${project.id}`, {
      state: { project, userId: user.user_id },
    });
  };
  useEffect(() => {
    fetchProjects();
  }, [currentPage]);

  const fetchProjects = () => {
    GetAssignedProjects(authTokens, currentPage)
      .then((response) => {
        setAssignedProjects(response.data.results);
        setTotalPages(response.data.total_pages);
        //console.log(response.data.results);
      })
      .catch((error) => {
        console.error("Error getting projects", error);
      });
  };

  const handleClick = () => {
    GetAssignedProjects(authTokens, currentPage, searchLabel).then(
      (response) => {
        setAssignedProjects(response.data.results);
        setTotalPages(response.data.total_pages);
        //console.log(response.data);
      }
    );
  };

  return (
    <div className="w-100">
      <HeaderDiv />

      <div className="d-flex justify-content-center align-items-center flex-wrap ">
        <div
          style={{ width: "95%", margin: "0 auto" }}
          className=" p-2 my-2 bg-second rounded-5 d-flex justify-content-between"
        >
          <BaseSearch
            placeholder={"Search by Project Name"}
            setSearchLabel={setSearchLabel}
            onClick={handleClick}
          ></BaseSearch>
        </div>

        <div style={{ width: "95%", margin: "0 auto" }}>
          <div
            // className="container d-flex justify-content-center align-content-between flex-wrap mt-3  mx-0"
            className="d-grid mt-3 gap-3 mx-0 pb-5"
            style={{
              gridTemplateColumns: "repeat(auto-fill, minmax(225px, 1fr) )",
            }}
          >
            {assignedProjects?.map((project) => (
              <div
                key={project.id}
                // className="col-12 col-md-12 col-lg-4 mt-5 d-flex justify-content-center align-items-center"
              >
                <CustomCard
                  title={project.name}
                  description={project.description}
                  date={project.created_at}
                  onViewClick={() => onViewClick(project)}
                />
              </div>
            ))}
          </div>
        </div>

        <div
          style={{
            bottom: "0px",
            marginTop: "10px",
            alignSelf: "end",
            width: "100%",
          }}
        >
          {totalPages > 1 && (
            <BasePagination
              pageCount={totalPages}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </div>
    </div>
  );
}
