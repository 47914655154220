export const getConfig = () => {
    const authTokens = localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null;
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens?.access}`,
      },
    };
    return config;
  };
  