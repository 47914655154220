import React, { useState, useEffect, useContext } from "react";
import "./timer.css"; // Import the CSS file
import {
  GetTimeEntries,
  PatchPauseTimeEntry,
  PatchStartTimeEntry,
  PatchStopTimeEntry,
  PostInitialTimeEntry,
} from "../APIs/EmployeeAPIS";
import AuthContext from "../../../context/AuthContext";

function Timer({ taskId }) {
  const [timeEntries, setTimeEntries] = useState([]);
  const { contextData } = useContext(AuthContext);
  const { authTokens } = contextData;
  const [duration, setDuration] = useState(0);

  const updateTimeEntiresStatus = (data) => {
    setTimeEntries((prev) => {
      let copy = [...prev];
      for (let i = 0; i < copy.length; i++) {
        if (copy[i].id === data.id) {
          copy[i] = data;
          return copy;
        }
      }
    });
  };

  const fetchTimeEntries = async () => {
    try {
      const response = await GetTimeEntries(authTokens);
      setTimeEntries(response.data);
    } catch (error) {
      console.error("Error fetching timeEntries:", error);
    }
  };

  let TaskActivatedBefore = timeEntries?.some((entry) => entry.task === taskId);
  let currentTimeEntry = timeEntries?.find(
    (currentEntry) => currentEntry.task === taskId
  );
  let currentTimeEntryId = currentTimeEntry?.id;
  let currentTimeEntryStatus = currentTimeEntry?.paused;
  let currentTimeEntryStopped = currentTimeEntry?.stopped;
  let currentTimeEntryDuration = currentTimeEntry?.duration;

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        // Tab has gained focus, reload the page
        fetchTimeEntries();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    fetchTimeEntries();
  }, []);

  const convertDurationToSeconds = (durationString) => {
    let days = 0;
    let [timePart, millisecondsPart] = durationString.split(".");
    let [hours, minutes, seconds] = timePart.split(":").map(Number);

    if (durationString.includes(" ")) {
      const [daysStr, timeStr] = durationString.split(" ");
      days = parseInt(daysStr);
      [hours, minutes, seconds] = timeStr.split(":").map(Number);
    }

    const totalSeconds = days * 86400 + hours * 3600 + minutes * 60 + seconds;
    return totalSeconds;
  };

  useEffect(() => {
    // Calculate total duration from time entries
    let totalDuration = 0;

    if (currentTimeEntry && currentTimeEntry.duration) {
      totalDuration = convertDurationToSeconds(currentTimeEntry.duration);
    }

    setDuration(totalDuration);

    // Update duration every second
    let timer;
    if (currentTimeEntryStatus === false) {
      timer = setInterval(() => {
        setDuration((prevDuration) => {
          // Stop the timer if duration reaches 23:59:00
          if (prevDuration >= 86340) {
            handlePause();
            handleStopped();
            // clearInterval(timer); // Stop the interval
            return prevDuration; // Return the current duration
          }
          return prevDuration + 1; // Increment duration
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [currentTimeEntryStatus, currentTimeEntry]);

  const formatTime = (seconds) => {
    const days = Math.floor(seconds / 86400);
    const hours = Math.floor((seconds % 86400) / 3600);
    const minutes = Math.floor(((seconds % 86400) % 3600) / 60);
    const secondsRemaining = ((seconds % 86400) % 3600) % 60;

    // Use Math.floor to remove decimals from secondsRemaining
    return `${days.toString().padStart(2, "0")}:${hours
      .toString()
      .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${Math.floor(
      secondsRemaining
    )
      .toString()
      .padStart(2, "0")}`;
  };

  const handleActivateTaskEntry = (activateTaskEntryObject) => {
    PostInitialTimeEntry(authTokens, activateTaskEntryObject)
      .then((response) => {
        setTimeEntries((prev) => [...prev, response.data]);
      })
      .catch((error) => {
        console.error("Error Posting TimeEntry", error);
      });
  };

  const handleTaskStart = (startObject, entryId) => {
    PatchStartTimeEntry(authTokens, startObject, entryId)
      .then((response) => {
        updateTimeEntiresStatus(response.data);
      })
      .catch((error) => {
        console.error("Error start patching TimeEntry", error);
      });
  };

  const handleTaskPaused = (endObject, entryId) => {
    PatchPauseTimeEntry(authTokens, endObject, entryId)
      .then((response) => {
        updateTimeEntiresStatus(response.data);
      })
      .catch((error) => {
        console.error("Error pause patching TimeEntry", error);
      });
  };
  const handleTaskStopped = (stoppedObject, entryId) => {
    PatchStopTimeEntry(authTokens, stoppedObject, entryId)
      .then((response) => {
        updateTimeEntiresStatus(response.data);
      })
      .catch((error) => {
        console.error("Error stop patching TimeEntry", error);
      });
  };

  const handlePause = async () => {
    // Fetch time entries to ensure currentTimeEntryId is up-to-date
    if (currentTimeEntryId) {
      const pausedObject = {
        paused: true,
        id: currentTimeEntryId,
      };
      const entryId = currentTimeEntryId;

      handleTaskPaused(pausedObject, entryId);
    }
  };

  const handleStopped = async () => {
    if (currentTimeEntryId) {
      const stoppedObject = {
        stopped: true,
        id: currentTimeEntryId,
      };
      const entryId = currentTimeEntryId;
      handleTaskStopped(stoppedObject, entryId);
    }
  };

  const handleStartOrActivateTimer = async () => {
    currentTimeEntryStatus = true;
    if (!TaskActivatedBefore) {
      const activateTaskEntryObject = {
        start_time: new Date(),
        task: taskId,
      };
      handleActivateTaskEntry(activateTaskEntryObject);
    } else if (TaskActivatedBefore) {
      const startObject = {
        paused: false,
        id: currentTimeEntryId,
      };
      const entryId = currentTimeEntryId;
      handleTaskStart(startObject, entryId);
    }
  };

  return (
    <div className="timer-container">
      {timeEntries.length > 0 && (
        <div>
          <div className="timer-label">Days : Hrs : Mins : Sec</div>
          <div className="timer">{formatTime(duration)}</div>
        </div>
      )}
      <div className="btn-container">
        <button
          onClick={() => {
            handleStartOrActivateTimer();
          }}
          disabled={
            currentTimeEntryStatus === false || currentTimeEntryStopped === true
          }
          style={{
            backgroundColor: "#4F9F9F", // Green color for Start button
            color: "white",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            marginRight: "10px",
          }}
        >
          Start
        </button>
        <button
          onClick={() => {
            handlePause();
          }}
          disabled={
            currentTimeEntryStatus === true || currentTimeEntryStopped === true
          }
          style={{
            backgroundColor: "#4F9F9F", // Red color for Pause button
            color: "white",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            marginRight: "10px",
          }}
        >
          Pause
        </button>
        <button
          onClick={() => {
            handleStopped();
          }}
          disabled={
            currentTimeEntryStatus === false || currentTimeEntryStopped === true
          }
          style={{
            backgroundColor: "#4F9F9F", // Red color for Pause button
            color: "white",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Stop
        </button>
      </div>
    </div>
  );
}

export default Timer;
