import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { generalURL } from "../components/utils/APIs/generalURL";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(
    localStorage.getItem("authTokens") ? jwt_decode(authTokens.access) : null
  );
  const [userInfo, setUserInfo] = useState(
    localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null
  );
  const navigate = useNavigate();
  const [refreshTimeout, setRefreshTimeout] = useState(null);
  const baseUrl = generalURL;

  const isTokenExpired = (token) => {
    const decodedToken = jwt_decode(token);
    const currentTime = Date.now() / 1000; // Convert to seconds
    return decodedToken.exp < currentTime;
  };

  const login = async (e) => {
    e.preventDefault();
    //console.log(e.target?.email?.value);
    //console.log(e.target?.password?.value);
    const response = await fetch(`${baseUrl}/api/token/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: e.target.email.value,
        password: e.target.password.value,
      }),
    });
    const data = await response.json();
    if (response.status === 200) {
      setAuthTokens(data);
      localStorage.setItem("authTokens", JSON.stringify(data));
      const decodedUser = jwt_decode(data.access);
      //console.log(decodedUser);
      setUser(decodedUser);
      if (decodedUser.role === "SUPERVISOR") {
        localStorage.setItem("authTokens", JSON.stringify(data));
        navigate("/hr/projects");
      } else if (decodedUser.role === "EMPLOYEE") {
        navigate("/tasks");
      } else if (decodedUser.role === "ADMIN") {
        navigate("/admin/projects");
      }
    }
    return response;
  };

  let getUser = async () => {
    let authTokens = JSON.parse(localStorage.getItem("authTokens"));
    let accessToken = authTokens.access;
    let config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const response = await axios.get(`${baseUrl}/getuser/`, config);
      const userInfo = response.data;
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      setUserInfo(userInfo);
    } catch (error) {
      //console.log(error);
    }
  };

  const logOut = () => {
    localStorage.removeItem("authTokens");
    localStorage.removeItem("userInfo");
    setUserInfo(null);
    setAuthTokens(null);
    navigate("/login");
  };

  const refreshToken = async () => {
    const refresh = authTokens.refresh;
    const response = await fetch(`${baseUrl}/api/token/refresh/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refresh }),
    });
    const data = await response.json();
    if (response.status === 200) {
      const newAuthTokens = {
        access: data.access,
        refresh: authTokens.refresh,
      };
      setAuthTokens(newAuthTokens);
      localStorage.setItem("authTokens", JSON.stringify(newAuthTokens));
      setUser(jwt_decode(newAuthTokens.access));
    } else {
      logOut();
      localStorage.removeItem("authTokens");
    }
  };
  //Task and TimeEntry section

  // end section of Task and

  // useEffect(() => {

  // }, [user]);

  // useEffect(() => {
  //   let fourMinutes = 1000 * 60 * 60 * 24;

  //   let interval = setInterval(() => {
  //     if (authTokens) {
  //       refreshToken();
  //     }
  //   }, fourMinutes);

  //   return () => clearInterval(interval);
  // }, [authTokens]);
  // useEffect(() => {
  //   let fourMinutes = 1000 * 60 * 60 * 24;

  //   let interval = setInterval(() => {
  //     if (authTokens) {
  //       const isAccessTokenExpired = isTokenExpired(authTokens.access);

  //       if (isAccessTokenExpired) {
  //         logOut();
  //         localStorage.removeItem("authTokens");
  //       } else {
  //         refreshToken();
  //       }
  //     }
  //   }, fourMinutes);

  //   return () => clearInterval(interval);
  // }, [authTokens]);

  useEffect(() => {
    let refreshTokenInterval;

    const checkTokenExpiration = () => {
      if (authTokens) {
        const isAccessTokenExpired = isTokenExpired(authTokens.access);

        if (isAccessTokenExpired) {
          // Access token expired, attempt to refresh
          refreshToken();
        }
      }
    };

    const startTokenRefreshInterval = () => {
      refreshTokenInterval = setInterval(() => {
        checkTokenExpiration();
      }, 1000 * 60 * 60 * 12); // Check 12 every hours (adjust as needed based on sliding token refresh lifetime)
    };

    const stopTokenRefreshInterval = () => {
      clearInterval(refreshTokenInterval);
    };

    // Check token expiration on component mount
    checkTokenExpiration();

    // Start the token refresh interval
    startTokenRefreshInterval();

    return () => {
      // Stop the token refresh interval on component unmount
      stopTokenRefreshInterval();
    };
  }, [authTokens]);

  const contextData = {
    login: login,
    user: user,
    authTokens: authTokens,
    getUser: getUser,
    userInfo: userInfo,
    logOut: logOut,
  };

  return (
    <AuthContext.Provider value={{ contextData }}>
      {children}
    </AuthContext.Provider>
  );
};
