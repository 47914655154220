import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const ChartComponent = ({ workingHrs, month }) => {
  // Check if workingHrs is an array before using find
  if (!Array.isArray(workingHrs)) {
    return <div>No data available for the selected month</div>;
  }

  const monthEntry = workingHrs.find((entry) => entry.month === month);
  // Check if monthEntry is defined before using its properties
  if (!monthEntry) {
    return <div>No data available for the selected month</div>;
  }

  const dayData = monthEntry.time_entries?.map((entry) => {
    // const date = Object.keys(entry)[0];
    // const day = new Date(date).getDate(); // Extract day from the date
    // const hours = entry[date];

    const date = Object.entries(entry)[0];
    const day = new Date(date[1]).getDate(); // Extract day from the dat
    // //console.log(day)
    const hours = Object.values(entry)[1]; // Assuming the value is in hours

    return {
      name: day.toString(),
      Hrs: parseFloat(hours.toFixed(2)), // Round to two decimal places
    };
  });

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={dayData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        barSize={20}
      >
        <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
        <YAxis
          label={{ value: "Working Hours", angle: -90, position: "insideLeft" }}
          domain={[0, 24]}
        />{" "}
        {/* Set Y-axis label and range */}
        <Tooltip />
        <Legend />
        <CartesianGrid strokeDasharray="3 3" />
        <Bar dataKey="Hrs" fill="#8884d8" background={{ fill: "#eee" }} />{" "}
        {/* Update dataKey to match the new name */}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ChartComponent;
