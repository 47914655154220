import React from "react";
import BaseSideBar from "../SideBar/BaseSideBar";

export function EmployeeSideBar() {
  const items = [
    {
      href: "/tasks",
      label: "Tasks",
      iClass: "bi bi-check2-circle",
    },
    {
      href: "/employee/projects",
      label: "Projects",
      iClass: "bi bi-archive ",
    },

    {
      href: "/employee/timesheets",
      label: "Timesheet",
      iClass: "bi bi-clock-history",
    },
  ];
  return (
    <div>
      <BaseSideBar data={items}></BaseSideBar>
    </div>
  );
}
