import React, { useEffect, useState } from "react";
import HeaderDiv from "./HeaderDiv";
import BaseSearch from "./BaseSearch";
import CustomEmployeeCard from "../Cards/CustomEmployeeCard";
import BasePagination from "./BasePagination";
import { useNavigate } from "react-router-dom";
import colors from "../../../resources/colors";

export default function Employees({ getUsersAPI }) {
  const [searchLabel, setSearchLabel] = useState("");
  const [employees, setEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigator = useNavigate();
  const onViewClick = (employee) => {
    navigator(`/hr/employee-tasks/${employee.id}`, { state: employee });
  };

  useEffect(() => {
    getUsersAPI(currentPage).then((data) => {
      setEmployees(data.results);
      setTotalPages(data.total_pages);
    });
  }, [currentPage]);

  const onSearchClick = (e) => {
    e.preventDefault();
    getUsersAPI(currentPage, searchLabel).then((data) => {
      setEmployees(data.results);
      setTotalPages(data.total_pages);
    });
  };
  return (
    // <div>
    //   <HeaderDiv title="Employees"></HeaderDiv>

    //   <div
    //     style={{ height: "80px" }}
    //     className=" container d-flex justify-content-between p-2 m-2 bg-white rounded-5"
    //   >
    //     <BaseSearch
    //       placeholder={"Search by User Name"}
    //       onClick={onSearchClick}
    //       setSearchLabel={setSearchLabel}
    //     ></BaseSearch>
    //   </div>
    //   <div className="container d-flex justify-content-center align-content-between flex-wrap mt-3 mx-0">
    //     {employees.map((employee, index) => {
    //       return (
    //         <div key={index}>
    //           <CustomEmployeeCard
    //             onViewClick={() => onViewClick(employee)}
    //             userFullName={employee.full_name}
    //             email={employee.email}
    //           ></CustomEmployeeCard>
    //         </div>
    //       );
    //     })}
    //   </div>
    //   {totalPages > 1 ? (
    //     <BasePagination
    //       pageCount={totalPages}
    //       setCurrentPage={setCurrentPage}
    //     ></BasePagination>
    //   ) : null}
    // </div>

    <>
      <HeaderDiv title="Employees"></HeaderDiv>

      <div
        style={{ width: "95%", margin: "0 auto" }}
        className=" p-2 my-2 bg-second rounded-5 d-flex justify-content-between"
      >
        <BaseSearch
          placeholder={"Search by User Name"}
          onClick={onSearchClick}
          setSearchLabel={setSearchLabel}
        ></BaseSearch>
      </div>

      <div style={{ backgroundColor: colors.bg_body }}>
        <div style={{ width: "95%", margin: "0 auto" }}>
          <div
            // className="container d-flex justify-content-center align-content-between flex-wrap mt-3 mx-0"
            className="d-grid mt-3 gap-3 mx-0 pb-5"
            style={{
              gridTemplateColumns: "repeat(auto-fill, minmax(225px, 1fr) )",
            }}
          >
            {employees?.map((employee, index) => {
              return (
                <div key={index}>
                  <CustomEmployeeCard
                    onViewClick={() => onViewClick(employee)}
                    userFullName={employee.full_name}
                    email={employee.email}
                  ></CustomEmployeeCard>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {totalPages > 1 ? (
        <div style={{ backgroundColor: colors.bg_body }}>
          <BasePagination
            pageCount={totalPages}
            setCurrentPage={setCurrentPage}
          ></BasePagination>
        </div>
      ) : null}
    </>
  );
}
