import React, { useContext, useEffect, useState } from "react";
import CustomCard from "../Cards/CustomProjectCard";
import "../../Styles/pageStyles.css";
import HeaderDiv from "./HeaderDiv";
import BaseSearch from "./BaseSearch";
import BaseButton from "./BaseButton";
import colors from "../../../resources/colors";
import {
  addProject,
  deleteProject,
  getAllEmployees,
  getAllProjects,
  updateProject,
} from "../APIs/coreApis";
import BasePagination from "./BasePagination";
import Form from "react-bootstrap/Form";
import BaseSelectWithSearch from "./BaseSelectWithSearch";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../context/AuthContext";

export default function BaseProjects() {
  const [projects, setProjects] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchLabel, setSearchLabel] = useState("");
  const [showProjectForm, setShowProjectForm] = useState(false);
  // const buttonStyles = { width: "20%", height: "100%", fontSize: "1.2rem" };
  const [currentFormButtonName, setCurrentFormButtonName] = useState("Create");
  const [selectedProject, setSelecteProject] = useState({});
  const [showAssignForm, setShowAssignForm] = useState(false);
  const [selectedEmployees, setSlectedEmployees] = useState([]);
  const [selectedEmployeesValues, setSlectedEmployeesValues] = useState([]);
  const [error, setError] = useState("");
  const { contextData } = useContext(AuthContext);
  const { user } = contextData;
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

  const navigator = useNavigate();
  useEffect(() => {
    if (showProjectForm === false) {
      setFormData({
        name: "",
        description: "",
      });
      setError("");
    }
  }, [showProjectForm]);
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError("");
  };
  useEffect(() => {
    getAllProjects(currentPage, searchLabel).then((data) => {
      setProjects(data.results);
      setTotalPages(data.total_pages);
    });
  }, [currentPage]);

  const onSearchClick = (e) => {
    e.preventDefault();
    getAllProjects(currentPage, searchLabel).then((data) => {
      setProjects(data.results);
      setTotalPages(data.total_pages);
    });
  };
  const onEditClick = (selectedProject) => {
    window.scrollTo(0, 0);
    setShowProjectForm(true);
    setSelecteProject(selectedProject);
    setCurrentFormButtonName("Update");
    setFormData({
      name: selectedProject.name,
      description: selectedProject.description,
    });
  };
  const onDeleteClick = (selectedProject) => {
    if (
      window.confirm(`Are you sure you want to delete ${selectedProject.name}?`)
    ) {
      deleteProject(selectedProject.id).then(() => {
        alert("Project Deleted successfully!");
        setProjects((prevProjects) =>
          prevProjects.filter((project) => project.id !== selectedProject.id)
        );
      });
    }
  };

  const formOnSubmit = (e) => {
    e.preventDefault();
    if (currentFormButtonName === "Create") {
      addProject(formData)
        .then((data) => {
          alert("Project Added successfully!");
          getAllProjects(currentPage, searchLabel).then((data) => {
            setProjects(data.results);
            setTotalPages(data.total_pages);
          });
          setShowProjectForm(false);
        })
        .catch((err) => {
          console.log("errrrrrrrrrrrrrrrrrr", err);
          if (err.response && err.response.data && err.response.data.name) {
            setError(err.response.data.name[0]);
          } else {
            setError("A project with this name already exist.");
          }
        });
    } else if (currentFormButtonName === "Update") {
      updateProject(selectedProject.id, formData)
        .then((data) => {
          alert("Project Updated successfully!");
          getAllProjects(currentPage, searchLabel).then((data) => {
            setProjects(data.results);
            setTotalPages(data.total_pages);
          });
          setShowProjectForm(false);
          setCurrentFormButtonName("Create");
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.name
          ) {
            setError(error.response.data.name[0]);
          } else {
            setError("A project with this name already exist.");
          }
        });
    }
  };
  const onAssignClick = (project) => {
    setSelecteProject(project);
    getAllEmployees().then((employees) => {
      setEmployees(employees);

      // Check if project.users is properly initialized and not empty
      let selectedUsers = [];
      if (project.users && project.users.length > 0) {
        selectedUsers = project.users.map((user) => {
          return { value: user.id, label: user.full_name };
        });
      }

      const creatorId = user.id; // Assuming `user.id` is the id of the current logged-in user
      if (
        !selectedUsers.find((user) => user.value === creatorId) &&
        creatorId !== project.creator_id // Avoid duplicating creator if already included as creator
      ) {
        // Add the creator to the selected users
        selectedUsers.push({ value: creatorId, label: user.full_name });
      }

      setSlectedEmployeesValues(selectedUsers);
      setShowAssignForm(true);
    });
  };

  const handleSelectChange = (e) => {
    setSlectedEmployeesValues(e);
    setSlectedEmployees(e);
  };
  const assignformOnSubmit = (e) => {
    e.preventDefault();
    if (selectedEmployees.length === 0) {
      // Show error message if no users are selected
      setError("The project should have at least 1 user.");
      return; // Exit early if no users are selected
    }
    let employeesIds = selectedEmployees?.map((employee) => employee.value);
    let projObj = {
      users: employeesIds,
    };
    updateProject(selectedProject.id, projObj).then((data) => {
      alert("Employees Assigned Successfully!");
      window.location.reload();
      setShowAssignForm(false);
      setCurrentFormButtonName("Create");
    });
  };
  const onViewClick = (project) => {
    if (user.role === "SUPERVISOR") {
      navigator(`/hr/project-tasks/${project.id}`, { state: { project } });
    } else if (user.role === "ADMIN") {
      navigator(`/admin/project-tasks/${project.id}`, { state: { project } });
    }
  };

  return (
    <div className="d-flex" style={{ height: "100vh", width: "76%" }}>
      <div className="w-100 m-0 p-0 container">
        <HeaderDiv title="Projects"></HeaderDiv>
        <div
          style={{
            width: "95%",
            alignItems: "center",
            gridTemplateColumns: "1fr 150px",
            margin: "0 auto",
          }}
          // className=" container d-flex justify-content-between p-2 my-2 bg-white rounded-5 flex-column flex-sm-row "
          className="p-2 my-2 bg-second rounded-5 d-sm-grid column-gap-3 text-center"
        >
          <BaseSearch
            placeholder={"Search by Project Name"}
            onClick={onSearchClick}
            setSearchLabel={setSearchLabel}
          ></BaseSearch>
          <BaseButton
            // styles={buttonStyles}
            name="Create Project +"
            onClick={() => {
              setFormData({
                name: "",
                description: "",
              });
              setCurrentFormButtonName("Create");
              setShowProjectForm(true);
              setError("");
            }}
          ></BaseButton>
        </div>
        <div>
          {/* assign form */}
          {showAssignForm && (
            <div
              className="container w-50  my-4 bg-white h-100 p-4 rounded-3 shadow"
              style={{ width: "95%", minWidth: "210px", maxWidth: "500px" }}
            >
              <Form onSubmit={assignformOnSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>{`Assign Employees for Project: "${selectedProject.name}"`}</Form.Label>

                  <BaseSelectWithSearch
                    optionShowing={true}
                    isMulti={true}
                    onChange={handleSelectChange}
                    values={employees?.map((employee) => {
                      return { value: employee.id, name: employee.full_name };
                    })}
                    value={selectedEmployeesValues}
                  ></BaseSelectWithSearch>
                </Form.Group>

                <div className="d-flex justify-content-center  align-content-center w-100">
                  <BaseButton
                    type={"submit"}
                    name={"Assign"}
                    className={"me-2"}
                    // styles={buttonStyles}
                  ></BaseButton>
                  <button
                    className="btn btn-danger  rounded-5   "
                    // style={{ ...buttonStyles, width: "17%" }}
                    onClick={() => {
                      setShowAssignForm(false);
                      setCurrentFormButtonName("Create");
                      setError("");
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            </div>
          )}
        </div>
        <div>
          {showProjectForm && (
            <div
              className="container w-50  my-4 bg-white h-100 p-4 rounded-3 shadow"
              style={{ width: "95%", minWidth: "210px", maxWidth: "500px" }}
            >
              <Form onSubmit={formOnSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    name="name"
                    type="text"
                    placeholder="Enter project name"
                    onChange={handleInputChange}
                    value={formData.name}
                  />
                  {error && <p style={{ color: "red" }}>{error}</p>}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    name="description"
                    as="textarea"
                    rows={3}
                    onChange={handleInputChange}
                    value={formData.description}
                  />
                </Form.Group>

                <div className="d-flex justify-content-center  align-content-center w-100">
                  <BaseButton
                    type={"submit"}
                    name={currentFormButtonName}
                    className={"me-2"}
                    // styles={buttonStyles}
                  ></BaseButton>
                  {/* <BaseButton type={"submit"} name={"Create"}></BaseButton> */}
                  <button
                    className="btn btn-danger  rounded-5   "
                    // style={{ ...buttonStyles, width: "20%" }}
                    onClick={() => {
                      setShowProjectForm(false);
                      setCurrentFormButtonName("Create");
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            </div>
          )}
        </div>

        <div style={{ backgroundColor: colors.bg_body }}>
          <div style={{ width: "95%", margin: "0 auto" }}>
            <div
              // className="container d-flex justify-content-center align-content-between flex-wrap mt-3  mx-0"
              className="d-grid mt-3 gap-3 mx-0 pb-5"
              style={{
                gridTemplateColumns: "repeat(auto-fill, minmax(225px, 1fr) )",
              }}
            >
              {projects?.map((project, index) => {
                return (
                  <div key={index}>
                    <CustomCard
                      title={project.name}
                      description={project.description}
                      date={project.created_at}
                      noOfEmployees={project.no_of_employees}
                      onDeleteClick={() => onDeleteClick(project)}
                      onEditClick={() => onEditClick(project)}
                      onAssignClick={() => onAssignClick(project)}
                      onViewClick={() => onViewClick(project)}
                    ></CustomCard>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: colors.bg_body, margin: "0px" }}>
          <BasePagination
            className={"m-0 p-0"}
            pageCount={totalPages}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}
