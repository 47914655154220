import React from "react";
import "../../resources/font.css";
import { Image, Nav, Navbar } from "react-bootstrap";
import "../../resources/colors";
import colors from "../../resources/colors";
import "../WelcomePage/burgermenu.css";
import body from "../../imgs/WelcomePage/body2.webp";
import WelcomeNavbar from "./WelcomeNavbar";

const fullScreenStyle = {
  height: "100vh",
  overflow: "hidden",
  margin: 0,
  padding: 0,
  backgroundColor: colors.bg_body,
};

export function WelcomePage() {
  return (
    <div style={fullScreenStyle} className="col col-12">
      <WelcomeNavbar></WelcomeNavbar>

      <div className="parent-img-h">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <div className="text-welcome-page">
          <h2 style={{ fontWeight: "bold" }}>Track Time,</h2>
          <h4>Plan Tasks,</h4>
          <h4>Increase Effeciency,</h4>
          <h4>All in one place.</h4>
          </div>
        </div>
        <div>
          <Image className="responsive-img" src={body} alt="body"></Image>
        </div>
      </div>
    </div>
  );
}
