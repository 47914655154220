import React, { useContext, useState } from "react";
import AuthContext from "../../../context/AuthContext";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import {
  CreateTask,
  GetDropEmployees,
  GetDropProjects,
} from "../APIs/AdminAPIs";
import { useEffect } from "react";
import Select from "react-select";

export function CreateTaskModal({ open, close, tasks, onTaskCreated }) {
  const { contextData, setUserData } = useContext(AuthContext);
  const { authTokens } = contextData;
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    project: "",
    user: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const [formErrors, setFormErrors] = useState({
    name: null,
    description: null,
    project: null,
    user: null,
  });
  const [employees, setEmployees] = useState([]);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    fetchEmployeesAndProjects();
  }, []);

  const fetchEmployeesAndProjects = async () => {
    try {
      const employeesResponse = await GetDropEmployees(authTokens);
      const projectsResponse = await GetDropProjects(authTokens);

      // Filter out employees with admin roles
      // const filteredEmployees = employeesResponse.data.filter(
      //   (employee) => employee.role && employee.role !== "ADMIN"
      // );

      setEmployees(employeesResponse.data);
      setProjects(projectsResponse.data);
    } catch (error) {
      console.error("Error fetching employees and projects", error);
    }
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    // Validate name
    if (!formData.name) {
      errors.name = "This field is required.";
      isValid = false;
    }

    // Validate project
    if (!formData.project) {
      errors.project = "This field is required.";
      isValid = false;
    }

    // Validate user
    if (!formData.user) {
      errors.user = "This field is required.";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSaveChanges = async () => {
    if (!validateForm()) return;
    setIsLoading(true);
    try {
      const response = await CreateTask(authTokens, formData);

      if (response.status === 201) {
        //console.log("Task created successfully", response.data);
        close();
        setIsLoading(false);
        onTaskCreated();
      } else if (response.status !== 201) {
        // Handle validation errors
        setIsLoading(false);
        const errors = response.data;
        setFormErrors({
          name: errors.name ? errors.name[0] : null,
          description: errors.description ? errors.description[0] : null,
          project: errors.project ? errors.project[0] : null,
          user: errors.user ? errors.user[0] : null,
        });
      } else {
        console.error("Error creating task", response.data);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error creating task", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: null });
  };

  return (
    <>
      <Modal show={open} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Create Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Task Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                onChange={handleInputChange}
                value={formData.name}
              />
              {formErrors.name && (
                <Form.Text className="text-danger">{formErrors.name}</Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="description">
              <Form.Label>Task Description</Form.Label>
              <Form.Control
                type="text"
                name="description"
                onChange={handleInputChange}
                value={formData.description}
              />
              {formErrors.description && (
                <Form.Text className="text-danger">
                  {formErrors.description}
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="projectSelect">
              <Form.Label>Project</Form.Label>
              <Form.Control
                as="select"
                name="project"
                onChange={handleInputChange}
                value={formData.project} // Use only the id
              >
                <option disabled value="">
                  Select a Project
                </option>
                {Object.values(projects)?.map((proj) => (
                  <option key={proj.id} value={proj.id}>
                    {" "}
                    {/* Use proj.id instead of proj.name */}
                    {proj.name}
                  </option>
                ))}
              </Form.Control>
              {formErrors.project && (
                <Form.Text className="text-danger">
                  {formErrors.project}
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group
              className="create-task-modal mb-3"
              controlId="EmployeeSelect"
            >
              <Form.Label>Employee</Form.Label>
              <Select
                options={employees.map((employee) => ({
                  value: employee.id,
                  label: `${employee.username} (${employee.role})`,
                }))}
                onChange={(selectedOption) =>
                  setFormData({ ...formData, user: selectedOption.value })
                }
                value={
                  formData.user
                    ? {
                        value: formData.user,
                        label: `${
                          employees.find(
                            (employee) => employee.id === formData.user
                          ).username
                        } (${
                          employees.find(
                            (employee) => employee.id === formData.user
                          ).role
                        })`,
                      }
                    : null
                }
                placeholder="Select an employee"
              />
              {formErrors.user && (
                <Form.Text className="text-danger">{formErrors.user}</Form.Text>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isLoading}
            variant="primary"
            onClick={handleSaveChanges}
          >
            {isLoading ? <div className="loading-spinner" /> : "Save Changes"}
          </Button>
          <Button className="btn-danger" onClick={close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
