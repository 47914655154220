import React from "react";
import WelcomeNavbar from "./WelcomeNavbar";

function TourPage() {
  return (
    <div>
      <WelcomeNavbar />
      <div className="container mt-5 bg-white">
        Tour
      </div>
    </div>
  );
}

export default TourPage;
