import React from "react";
import BaseSideBar from "../../SideBar/BaseSideBar";

export default function HRSideBar() {
  const items = [
    {
      href: "/hr/projects",
      label: "Projects & Tasks",
      iClass: "bi bi-archive ",
    },
    {
      href: "/hr/tasks",
      label: "Tasks",
      iClass: "bi bi-check2-circle",
    },
    {
      href: "/hr/employees",
      label: "Employees",
      iClass: "bi bi-people-fill",
    },
    {
      href: "/hr/timesheets",
      label: "Timesheets",
      iClass: "bi bi-clock-history",
    },
  ];
  return <BaseSideBar data={items}></BaseSideBar>;
}
