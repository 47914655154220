import axios from "axios";
import { generalURL } from "../../utils/APIs/generalURL";

let base = generalURL;
let employee_url = `${base}/employee`;

export const GetAssignedProjects = async (
  authTokens,
  currentPage,
  searchLabel
) => {
  try {
    let url = `${employee_url}/projects/?page=${currentPage}`;
    if (searchLabel) {
      url += `&name=${searchLabel}`;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    console.error("Error getting projects", error);
    return error.response;
  }
};

export const GetDropDownProjects = async (authTokens) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    const response = await axios.get(`${employee_url}/assigned-tasks/`, config);
    return response;
  } catch (error) {
    console.error("Error getting projects", error);
    return error.response;
  }
};

export const addTask = async (authTokens, formData) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    const respose = await axios.post(
      `${employee_url}/add-tasks/`,
      formData,
      config
    );
    return respose;
  } catch (error) {
    console.error("Error adding tasks", error);
    return error.response;
  }
};

export const getTask = async (authTokens, currentPage, searchLabel) => {
  try {
    let url = `${employee_url}/tasks/?page=${currentPage}`;
    if (searchLabel) {
      url += `&name=${searchLabel}`;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    console.error("Error getting tasks", error);
    return error.response;
  }
};

export const PostInitialTimeEntry = async (authTokens, data) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    const response = await axios.post(
      `${base}/core/time-entries/`,
      data,
      config
    );
    return response;
  } catch (error) {
    console.error("Error Post Initial initialTimeEntry", error);
    return error.response;
  }
};
export const PatchEndTimeEntry = async (authTokens, data,entryId) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    const response = await axios.put(
      `${base}/core/time-entries/${entryId}/`,
      data,
      config
    );
    return response;
  } catch (error) {
    console.error("Error Patch End EndTimeEntry", error);
    return error.response;
  }
};
export const PatchPauseTimeEntry = async (authTokens, data,entryId) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    const response = await axios.put(
      `${base}/core/time-entries/${entryId}/`,
      data,
      config
    );
    return response;
  } catch (error) {
    console.error("Error Patch Pause EndTimeEntry", error);
    return error.response;
  }
};
export const PatchStopTimeEntry = async (authTokens, data,entryId) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    const response = await axios.put(
      `${base}/core/time-entries/${entryId}/`,
      data,
      config
    );
    return response;
  } catch (error) {
    console.error("Error Patch Stop EndTimeEntry", error);
    return error.response;
  }
};



export const PatchStartTimeEntry = async (authTokens, data,entryId) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    const response = await axios.put(
      `${base}/core/time-entries/${entryId}/`,
      data,
      config
    );
    return response;
  } catch (error) {
    console.error("Error Patch Start EndTimeEntry", error);
    return error.response;
  }
};
export const GetTimeEntries = async (authTokens) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    const response = await axios.get(
      `${base}/core/time-entries/`,
      config
    );
    return response;
  } catch (error) {
    console.error("Error getting timeEntries", error);
    return error.response;
  }
};

export const GetWorkingHrs = async (authTokens, user_id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authTokens.access}`,
      },
    };
    const response = await axios.get(
      `${employee_url}/my-time-sheet/?year=2023&user_id=${user_id}`,
      config
    );
    return response;
  } catch (error) {
    console.error("Error getting timesheet", error);
    return error.response;
  }
};
