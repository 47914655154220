import React from "react";
import Papa from "papaparse";
import "../../../resources/font.css";

const convertToCSV = (data) => {
  const csv = Papa.unparse({
    data: data,
  });
  return csv;
};
const buttonStyle = {
  fontFamily: "'Syne', sans-serif", // Apply 'Syne' font
  // Add other styles as needed
};
const downloadCSV = (csvData, fileName) => {
  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");

  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export default function ExportToCSVBtn({
  data,
  fileName,
  onClic,
  nameButton,
  titlepop,
}) {
  const handleExportCSV = () => {
    // onClick && onClick();
    //console.log(data.length);
    const csvData = convertToCSV(data);
    downloadCSV(csvData, fileName);
  };
  return (
    <div className="float-end">
      {titlepop ? (
        <button
          className="btn btn-success"
          title="Export this to know how should you upload the batch user file"
          onClick={handleExportCSV}
          style={buttonStyle}
        >
          {nameButton ? nameButton : "export"}
        </button>
      ) : (
        <button className="btn btn-success" onClick={handleExportCSV}>
          {nameButton ? nameButton : "export"}
        </button>
      )}
    </div>
  );
}
