import React from "react";
import { Link } from "react-router-dom";

function PageNOtFound() {
  return (
    <div
      className="d-flex align-items-center justify-content-center flex-column"
      style={{ height: "100vh" }}
    >
      <h2 style={{fontSize: "4rem"}}>
        Page NOt Found! <span style={{ color: "red" }}>404</span>
      </h2>
      <p style={{fontSize: "2rem"}}>
        Go To
        <a href="/"> Welcome Page</a>
      </p>
    </div>
  );
}

export default PageNOtFound;
