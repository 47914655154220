import React, { useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { TaskModal } from "./TaskModal";
import HeaderDiv from "../../utils/Components/HeaderDiv";
import AuthContext from "../../../context/AuthContext";
import {
  GetTimeEntries,
  PatchEndTimeEntry,
  PatchPauseTimeEntry,
  PatchStartTimeEntry,
  PostInitialTimeEntry,
  getTask,
} from "../APIs/EmployeeAPIS";
import BasePagination from "../../utils/Components/BasePagination";
import BaseSearch from "../../utils/Components/BaseSearch";
import dayjs from "dayjs";
import BaseButton from "../../utils/Components/BaseButton";
import Timer from "./Timer";
export function Tasks() {
  const { contextData } = useContext(AuthContext);
  const { authTokens } = contextData;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [task, setTask] = useState([]);
  const [onGoingTask, setOnGoingTask] = useState(
    parseInt(localStorage.getItem("taskId"))
  );
  const [searchLabel, setSearchLabel] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 9;
  const durationToSeconds = (duration) => {
    const [hours, minutes, seconds] = duration.split(":");
    const totalSeconds = +hours * 3600 + +minutes * 60 + +seconds;
    return totalSeconds;
  };

  const fetchTasks = () => {
    getTask(authTokens, currentPage, searchLabel)
      .then((response) => {
        //console.log("Response Data Results:", response?.data?.results);
        setTask(response?.data?.results);
        setTotalPages(response?.data?.total_pages);
        //console.log(response.data.results);
      })
      .catch((error) => {
        console.error("Error fetching tasks:", error);
      });
  };

  useEffect(() => {
    fetchTasks();
  }, [currentPage]);

  const handleSearch = () => {
    getTask(authTokens, currentPage, searchLabel).then((response) => {
      setTask(response?.data?.results);
      setTotalPages(response?.data?.total_pages);
      //console.log(response.data);
    });
  };

  const handleTaskSaved = () => {
    fetchTasks();
  };

  return (
    <div className="d-flex" style={{ height: "100vh", width: "76%" }}>
      <div className="w-100 m-0 p-0 container">
        <HeaderDiv />
        <div
          style={{
            width: "95%",
            alignItems: "center",
            gridTemplateColumns: "1fr 150px",
            margin: "0 auto",
            justifyContent: "center",
          }}
          // className=" container d-flex justify-content-between p-2 my-2 bg-white rounded-5 flex-column flex-sm-row "
          className="p-2 my-2 bg-second rounded-5 d-sm-grid column-gap-3 text-center"
        >
          <BaseSearch
            placeholder="Search By Task Name"
            setSearchLabel={setSearchLabel}
            onClick={handleSearch}
          />
          <BaseButton
            name="Add Task"
            // styles={{ background: "colors.bg_nav !important" }}
            onClick={() => setIsModalOpen(true)}
          >
            Add Task
          </BaseButton>
        </div>
        {isModalOpen && (
          <TaskModal
            open={isModalOpen}
            close={() => setIsModalOpen(false)}
            onTaskSaved={handleTaskSaved}
          />
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {task?.map((t) => (
            <Card
              style={{
                width: "75%",
                minWidth: "210px",
                maxWidth: "500px",
                margin: "20px 0",
              }}
              className="shadow"
              key={t?.id}
            >
              <Card.Body>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      maxWidth: "100%",
                    }}
                  >
                    <h5>Name :</h5>
                    <span>
                      {" "}
                      <h5 style={{ marginLeft: "5px" }}>{t.name}</h5>
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                  >
                    <h5>Project :</h5>
                    <span>
                      {" "}
                      <h5 style={{ marginLeft: "5px" }}>{t.project.name}</h5>
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      width: "100%",
                      margin: "0px",
                    }}
                  >
                    <h5>Description :</h5>
                    <span>
                      {" "}
                      <h5 style={{ marginLeft: "5px" }}>{t.description}</h5>
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "wrap",
                      width: "100%",
                      margin: "0px",
                    }}
                  >
                    <h5>Created At :</h5>
                    <span>
                      {" "}
                      <h5 style={{ marginLeft: "5px" }}>
                        {dayjs(t.created_at).format("YYYY-MM-DD , HH:mm")}
                      </h5>
                    </span>
                  </div>

                  {/* <MyStopwatch
                    onTaskEnd={handleTaskEnd}
                    taskId={t.id}
                    onGoingTask={onGoingTask}
                    setOnGoingTask={setOnGoingTask}
                    totalTime={
                      t.total_duration ? durationToSeconds(t.total_duration) : 0
                    }
                    startTime={localStorage.getItem("startTime")}
                    latestTime={localStorage.getItem("latest")}
                    pausedTime={localStorage.getItem("whenPaused")}
                  /> */}
                  <Timer taskId={t.id} />
                </div>
              </Card.Body>
            </Card>
          ))}
          {totalPages > 1 && (
            <BasePagination
              pageCount={totalPages}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </div>
    </div>
  );
}
