import React from "react";
import WelcomeNavbar from "./WelcomeNavbar";
import aboutImage from "../../imgs/AboutPage/about2.jpg";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap styles
import "../Styles/aboutpage.css";
export default function AboutPage() {
  return (
    <div className="h-100">
      <WelcomeNavbar></WelcomeNavbar>

      <div className="container mt-5 bg-white">
        <div className="row">
          <div className="col-md-6">
            <h2 className="py-2">Our Journey</h2>
            <div className="py-2">
              <ul>
                <li>
                  {" "}
                  <h5>
                    Founded in 2010, Micro Engineering Tech Inc. (METI) has
                    established itself as a forward-looking and innovative
                    company.
                  </h5>
                </li>
                <li>
                  {" "}
                  <h5>
                    Born from a vision to see opportunities in challenges, METI
                    has grown in size, capability, and impact.
                  </h5>
                </li>
                <li>
                  {" "}
                  <h5>
                    Driven by a commitment to excellence and a belief in the
                    power of technology to make a difference.
                  </h5>
                </li>
              </ul>
            </div>
            <h2 className="py-2">Our Mission</h2>
            <div className="py-2">
              <ul>
                <li>
                  {" "}
                  <h5>Empowering teams to confront and overcome challenges.</h5>
                </li>
                <li>
                  {" "}
                  <h5>
                    Nurturing an environment where creativity and
                    solution-focused thinking thrive.
                  </h5>
                </li>
                <li>
                  {" "}
                  <h5>
                    Providing fair opportunities to all team members to build a
                    stronger organization.
                  </h5>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <div className="w-100 h-100 mt-1">
              <img
                src={aboutImage}
                style={{ objectFit: "cover", width: "100%", height: "100%" }}
                className="pt-5"
                alt="About Us"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
