import React, { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AuthContext from "../../../context/AuthContext";
import { DeleteEntry, GetTimeEntries } from "../APIs/AdminAPIs";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import "./spinner.css";
import CreateTimeModal from "./CreateTimeModal";
import EditTimeModal from "./EditTimeModal";

export function TimeEntryModal({ open, close, taskId, nameTask, data }) {
  const { contextData } = useContext(AuthContext);
  const { authTokens } = contextData;
  const [TimeEntries, setTimeEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [countRows, setCountRows] = useState();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 9,
    page: 0,
  });
  const [createTimeEntryModalOpen, setCreateTimeEntryModalOpen] =
    useState(false);
  const [editTimeEntryModalOpen, setEditTimeEntryModalOpen] = useState(false);
  const [selectedTimeEntry, setSelectedTimeEntry] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  // ... (existing code)
  //console.log("dataaoooooooooooo", data);
  const handleEditTimeEntry = (timeId, params) => {
    setSelectedTimeEntry(params.row);
    setEditTimeEntryModalOpen(true);
    //console.log(`edit time entry clicked ${timeId}`);
  };

  useEffect(() => {
    fetchTime();
  }, [paginationModel]);

  const fetchTime = async () => {
    try {
      setLoading(true);
      const response = await GetTimeEntries(
        authTokens,
        taskId,
        paginationModel.page + 1,
        paginationModel.pageSize
      );
      setTimeEntries(response.data.results);
      //console.log(response.data.results);
      setCountRows(response.data.count);
      setTimeout(() => {
        setLoading(false);
      }, 500);
      //console.log(response.data);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };
  const handleDeleteEntry = async (entryId) => {
    const isConfirmed = window.confirm(
      `Are you sure you want to delete entry id: "${entryId}"?`
    );

    // If user confirms, proceed with deletion
    if (isConfirmed) {
      await DeleteEntry(authTokens, entryId);

      // Fetch the new data after deletion
      try {
        const response = await GetTimeEntries(
          authTokens,
          taskId,
          paginationModel.page + 1,
          paginationModel.pageSize
        );

        const remainingEntries = response.data.results || [];

        // Update the local state with the new data
        setTimeEntries(remainingEntries);

        // Check if there are no more entries on the current page
        if (remainingEntries.length === 0 && paginationModel.page > 1) {
          // If there are no more entries on the current page and it's not the first page,
          // go back to the previous page
          const newPage = paginationModel.page - 1;

          // Update the pagination model
          setPaginationModel((prevModel) => ({
            ...prevModel,
            page: newPage,
          }));

          // Fetch the data for the new page
          const updatedResponse = await GetTimeEntries(
            authTokens,
            taskId,
            newPage,
            paginationModel.pageSize
          );

          const updatedEntries = updatedResponse.data.results || [];
          setTimeEntries(updatedEntries);
        }
      } catch (error) {
        console.error("Error fetching time entries after deletion:", error);
      }
    }
  };

  const handleCreateTimeEntry = () => {
    setCreateTimeEntryModalOpen(true);
    setSelectedEmployee(data);
    //console.log("create time entry clicked");
  };
  //console.log("selectedEmployee", selectedEmployee);

  const handleTimeEntryCreated = () => {
    fetchTime();
  };
  const handleTimeEntryUpdated = () => {
    fetchTime();
  };

  const columns = [
    {
      field: "user.email",
      headerName: "Employee",
      width: 150,
      valueGetter: (params) => (params.row.user ? params.row.user.email : ""),
    },
    {
      field: "start_time",
      headerName: "Start_Time",
      width: 110,
      valueGetter: (params) => params.row.start_time,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toISOString().split("T")[0]; // Format as yyyy-mm-dd
      },
    },
    {
      field: "end_time",
      headerName: "End_Time",
      width: 200,
      valueGetter: (params) => params.row.end_time,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toISOString().split("T")[0]; // Format as yyyy-mm-dd
      },
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 200,
      valueFormatter: (params) => {
        const date = params.value.slice(0, 8);
        return date;
      },
    },
    {
      width: 125,
      disableSorting: true,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={() => handleEditTimeEntry(params.row.id, params)}
            color="primary"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDeleteEntry(params.row.id)}
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const CustomToolbar = () => (
    <div style={{ padding: "10px" }}>
      <GridToolbarContainer>
        <GridToolbarExport />
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <GridToolbarQuickFilter />{" "}
      </GridToolbarContainer>
      {/* <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleCreateTimeEntry}
        style={{ marginTop: "10px" }}
      >
        Create Time-Entry
      </Button> */}
    </div>
  );
  return (
    <div>
      <Modal size="xl" show={open} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>{nameTask}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="loading-spinner-container">
              <div className="loading-spinner"></div>
            </div>
          ) : (
            <DataGrid
              rows={TimeEntries || []}
              columns={columns}
              // pageSizeOptions={[9]}
              autoHeight={false}
              disableSelectionOnClick
              onSelectionModelChange
              disableRowSelectionOnClick
              slots={{
                toolbar: CustomToolbar,
              }}
              pagination
              rowCount={countRows}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              paginationMode="server"
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginRight: "10px" }}
            variant="contained"
            color="error"
            onClick={close}
          >
            Close
          </Button>
          <Button variant="contained" color="primary" onClick={close}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* {createTimeEntryModalOpen && (
        <CreateTimeModal
          open={createTimeEntryModalOpen}
          close={() => setCreateTimeEntryModalOpen(false)}
          onTimeEntrySaved={handleTimeEntryCreated}
          taskId={taskId}
          selectedEmployee={selectedEmployee}
        />
      )} */}

      {editTimeEntryModalOpen && (
        <EditTimeModal
          open={editTimeEntryModalOpen}
          close={() => setEditTimeEntryModalOpen(false)}
          onTimeEntrySaved={handleTimeEntryUpdated}
          taskId={taskId}
          initialData={selectedTimeEntry} // Pass the selected time entry data for editing
        />
      )}
    </div>
  );
}
