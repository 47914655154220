import * as React from "react";
import Accordion from "react-bootstrap/Accordion";
import BaseTable from "./BaseTable";

export default function BasicAccordion({ projects }) {
  const headers = ["Task Name", "Description", "Total Duration"];
  const parseDuration = (duration) => {
    let days = 0;
    let [timePart, millisecondsPart] = duration.split(".");
    let [hours, minutes, seconds] = timePart.split(":").map(Number);

    if (duration.includes(" ")) {
      const [daysStr, timeStr] = duration.split(" ");
      days = parseInt(daysStr);
      [hours, minutes, seconds] = timeStr.split(":").map(Number);
    }

    const totalSeconds = days * 86400 + hours * 3600 + minutes * 60 + seconds;
    return totalSeconds;
  };

  const formatDuration = (seconds) => {
    const padZero = (number) => (number < 10 ? `0${number}` : `${number}`);
    const days = Math.floor(seconds / 86400);
    const remainingSeconds = seconds % 86400;
    const hours = Math.floor(remainingSeconds / 3600);
    const remainingSecondsAfterHours = remainingSeconds % 3600;
    const minutes = Math.floor(remainingSecondsAfterHours / 60);
    const finalSeconds = Math.floor(remainingSecondsAfterHours % 60);
    return `${padZero(days)}:${padZero(hours)}:${padZero(minutes)}:${padZero(
      finalSeconds
    )}`;
  };

  return projects?.map((row, index) => {
    return (
      <Accordion defaultActiveKey={index}>
        <Accordion.Item eventKey={0}>
          <Accordion.Header>{row.project.name}</Accordion.Header>
          <Accordion.Body>
            <BaseTable
              data={{
                headers: headers,
                rows: row.tasks?.map((task) => {
                  return {
                    "Task Name": task.name,
                    Description: task.description,
                    "Total Duration": task?.total_duration
                      ? formatDuration(parseDuration(task.total_duration))
                      : "00:00:00",
                  };
                }),
              }}
            ></BaseTable>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  });
}
