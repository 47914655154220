import React from "react";
import Accordion from "react-bootstrap/Accordion";
import ChartComponent from "../../Employee/TimeSheets/ChartComponent";

export function ChartAccordion({ workingHrs }) {
  const months = [
    { name: "January", number: 1 },
    { name: "February", number: 2 },
    { name: "March", number: 3 },
    { name: "April", number: 4 },
    { name: "May", number: 5 },
    { name: "June", number: 6 },
    { name: "July", number: 7 },
    { name: "August", number: 8 },
    { name: "September", number: 9 },
    { name: "October", number: 10 },
    { name: "November", number: 11 },
    { name: "December", number: 12 },
  ];

  return (
    <div style={{ width: "100%", height: "", overflow: "hidden" }}>
      <Accordion>
        {months?.map((month, index) => (
          <Accordion.Item key={index} eventKey={index}>
            <Accordion.Header>{month.name}</Accordion.Header>
            <Accordion.Body style={{ height: "400px", width: "100%" }}>
              <ChartComponent workingHrs={workingHrs} month={month.number} />
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
}
