import React from "react";
import Employees from "../../utils/Components/Employees";
import { getEmployees } from "../APIs/HRAPIs";

export default function EmployeesHR() {
  return (
    <div className="d-flex " style={{ height: "100vh" }}>
      <div className=" w-100 container m-0 p-0 h-100">
        <Employees getUsersAPI={getEmployees}></Employees>
      </div>
    </div>
  );
}
