import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import HeaderDiv from "../../utils/Components/HeaderDiv";
import { getTasks } from "../APIs/HRAPIs";
import BasePagination from "../../utils/Components/BasePagination";
import CustomTaskCard from "../../utils/Cards/CustomTaskCard";
import colors from "../../../resources/colors";
import ExportToCSVBtn from "../../utils/Components/ExportToCSVBtn";

export default function ProjectTasks() {
  const { state } = useLocation();
  const currentProject = state.project;
  const userId = state.userId;
  const [tasks, setTasks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [flattenedData, setFlattenedData] = useState([]);
  const [totalProjectTime, setTotalProjectTime] = useState("00:00:00:00");

  const collectData = async () => {
    let collectedData = [];

    for (let i = 1; i <= totalPages; i++) {
      if (userId) {
        const data = await getTasks(i, currentProject?.id, userId);
        collectedData.push(...data.results);
      } else {
        const data = await getTasks(i, currentProject?.id);

        collectedData.push(...data.results);
      }
    }

    setFlattenedData(collectedData);
    flattenData(collectedData);
  };
  const flattenData = (data) => {
    setFlattenedData(
      data?.map((task) => ({
        taskName: task.name,
        employeeName:task.user?.full_name.replace(/\s+/g,' ').trim() || "",
        description: task.description,
        timeCreated: task.created_at,
        totalDuration: task.total_duration
          ? formatDuration(parseDuration(task.total_duration))
          : "00:00:00",
      }))
    );
  };
  const formatDuration = (seconds) => {
    const padZero = (number) => (number < 10 ? `0${number}` : `${number}`);
    const days = Math.floor(seconds / 86400);
    const remainingSeconds = seconds % 86400;
    const hours = Math.floor(remainingSeconds / 3600);
    const remainingSecondsAfterHours = remainingSeconds % 3600;
    const minutes = Math.floor(remainingSecondsAfterHours / 60);
    const finalSeconds = Math.floor(remainingSecondsAfterHours % 60);
    return `${padZero(days)}:${padZero(hours)}:${padZero(minutes)}:${padZero(
      finalSeconds
    )}`;
  };
  const calculateTotalProjectTime = () => {
    const totalDurationSum = flattenedData.reduce(
      (sum, task) => sum + parseDuration(task.totalDuration),
      0
    );
  };

  const parseDuration = (duration) => {
    let days = 0;
    let [timePart, millisecondsPart] = duration.split(".");
    let [hours, minutes, seconds] = timePart.split(":").map(Number);

    if (duration.includes(" ")) {
      const [daysStr, timeStr] = duration.split(" ");
      days = parseInt(daysStr);
      [hours, minutes, seconds] = timeStr.split(":").map(Number);
    }

    const totalSeconds = days * 86400 + hours * 3600 + minutes * 60 + seconds;
    return totalSeconds;
  };
  //------------------------------------------------- calculate total project time ---------------------------------//

  const totalDur = flattenedData
    .filter((task) => task.totalDuration !== "00:00:00") // Filter out records with totalDuration of "00:00:00"
    .map((task) => task.totalDuration);

  function timeToSeconds(timeStr) {
    const [days, hours, minutes, seconds] = timeStr.split(":").map(Number);
    return days * 86400 + hours * 3600 + minutes * 60 + seconds;
  }

  // Calculate total duration in seconds
  const totalDurInSeconds = totalDur.reduce(
    (total, interval) => total + timeToSeconds(interval),
    0
  );

  // Convert total duration to days, hours, minutes, and seconds
  let days = Math.floor(totalDurInSeconds / 86400);
  let remainingSeconds = totalDurInSeconds % 86400;
  let hours = Math.floor(remainingSeconds / 3600);
  remainingSeconds %= 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  remainingSeconds %= 60;
  const seconds = Math.floor(remainingSeconds);

  // Format the total duration
  const formattedTotalDur = `${days.toString().padStart(2, "0")}:${hours
    .toString()
    .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  const padZero = (number) => (number < 10 ? `0${number}` : `${number}`);

  //---------------------------------------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    if (userId) {
      getTasks(currentPage, currentProject?.id, userId).then((data) => {
        setTasks(data.results);
        setTotalPages(data.total_pages);
      });
    } else {
      getTasks(currentPage, currentProject?.id).then((data) => {
        setTasks(data.results);
        setTotalPages(data.total_pages);
      });
    }
  }, [currentPage]);

  useEffect(() => {
    calculateTotalProjectTime();
  }, [flattenedData]);

  useEffect(() => {
    if (totalPages > 0) {
      collectData().then(() => {
        calculateTotalProjectTime();
      });
    }
  }, [totalPages]);

  return currentProject ? (
    <div className="d-flex" style={{ height: "100vh" }}>
      <div className=" container">
        <HeaderDiv title={`${currentProject.name} Tasks`}></HeaderDiv>
        <div>
          <ExportToCSVBtn
            data={flattenedData}
            fileName={`${currentProject.name} timesheet`}
          ></ExportToCSVBtn>
        </div>
        <div className="text-center fs-5">
          {userId ? (
            ""
          ) : (
            <span className="text-bg-light">
              {" Total Project Time: "}
              <span className="text-danger">
                {formattedTotalDur ? formattedTotalDur : "Loading..."}
              </span>
            </span>
          )}
        </div>
        <div className="container d-flex justify-content-center align-content-between flex-wrap mt-3 mx-0">
          {tasks?.map((task, index) => {
            return (
              <div key={index}>
                <CustomTaskCard
                  title={task.name}
                  description={task.description}
                  date={task.created_at}
                  userFullName={task.user.full_name}
                  total_duration={task.total_duration}
                ></CustomTaskCard>
              </div>
            );
          })}
        </div>
        {totalPages > 1 ? (
          <div style={{ backgroundColor: colors.bg_body, margin: "0px" }}>
            <BasePagination
              className={"m-0 p-0"}
              pageCount={totalPages}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          </div>
        ) : null}
      </div>
    </div>
  ) : (
    <div className="col-9 w-75 container">
      <HeaderDiv title={`No Information Available`}></HeaderDiv>
    </div>
  );
}
