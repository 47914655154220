import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AuthContext from "../../../context/AuthContext";
import MainSideBar from "../../SideBar/MainSideBar";
import PageNOtFound from "../../PageNOtFound";

export const AdminRoute = () => {
  let { contextData } = useContext(AuthContext);
  let { authTokens, user } = contextData;
  const auth = authTokens;
  return auth && user?.role === "ADMIN" ? (
    <>
      <MainSideBar />
      <Outlet />
    </>
  ) : (
    <PageNOtFound />
    // <Navigate to="/admin/projects" />
  );
};
