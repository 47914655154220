import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderDiv from "./HeaderDiv";
import { capitalizeEveryName } from "../Cards/CustomEmployeeCard";
import BasicAccordion from "./BasicAccordion";
import { getTimeSheetByUserId } from "../APIs/coreApis";
import BaseYearSelector from "./BaseYearSelector";
import ExportToCSVBtn from "./ExportToCSVBtn";

export default function EmployeeTimeSheet({ RouteUrl }) {
  const navigator = useNavigate();
  const { state } = useLocation();
  const employeeName = state.label;
  const employeeId = state.value;
  const [projects, setProjects] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const formatDuration = (seconds) => {
    const padZero = (number) => (number < 10 ? `0${number}` : `${number}`);
    const days = Math.floor(seconds / 86400);
    const remainingSeconds = seconds % 86400;
    const hours = Math.floor(remainingSeconds / 3600);
    const remainingSecondsAfterHours = remainingSeconds % 3600;
    const minutes = Math.floor(remainingSecondsAfterHours / 60);
    const finalSeconds = Math.floor(remainingSecondsAfterHours % 60);
    return `${padZero(days)}:${padZero(hours)}:${padZero(minutes)}:${padZero(
      finalSeconds
    )}`;
  };

  const parseDuration = (duration) => {
    let days = 0;
    let [timePart, millisecondsPart] = duration.split(".");
    let [hours, minutes, seconds] = timePart.split(":").map(Number);

    if (duration.includes(" ")) {
      const [daysStr, timeStr] = duration.split(" ");
      days = parseInt(daysStr);
      [hours, minutes, seconds] = timeStr.split(":").map(Number);
    }

    const totalSeconds = days * 86400 + hours * 3600 + minutes * 60 + seconds;
    return totalSeconds;
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };
  const onYearClick = () => {
    navigator(`${RouteUrl}`, {
      state: { selectedYear: selectedYear, employeeId: employeeId },
    });
  };
  useEffect(() => {
    getTimeSheetByUserId(employeeId).then((data) => {
      setProjects(data);
      //console.log(data);
    });
  }, []);
  //console.log(state);
  const flattenedData = projects
    ?.map(({ project, tasks }) => {
      return tasks?.map((task) => ({
        projectName: project.name,
        taskName: task.name,
        description: task.description,
        totalDuration: task.total_duration
          ? formatDuration(parseDuration(task.total_duration))
          : "00:00:00",
      }));
    })
    .flat();

  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <HeaderDiv
        title={capitalizeEveryName(employeeName) + " - Time Sheet by Project"}
      ></HeaderDiv>

      <div className="w-100 d-flex row justify-content-center align-content-center">
        <div className="col-sm-12 col-md-6 col-lg-3 d-flex flex-column">
          <div className="d-flex justify-content-center mt-3 mb-2 align-content-center">
            <ExportToCSVBtn
              data={flattenedData}
              fileName={`${employeeName}`}
            ></ExportToCSVBtn>
          </div>
          <h5 className="text-center">View by Year</h5>
          <BaseYearSelector
            className={"my-3 p-1"}
            selectedYear={selectedYear}
            onChange={handleYearChange}
          ></BaseYearSelector>
          <button className="btn btn-success m-auto" onClick={onYearClick}>
            View
          </button>
        </div>
      </div>

      <div className="row justify-content-center align-content-center mt-3">
        <div className="col-12 col-md-10 col-lg-8">
          <BasicAccordion projects={projects}></BasicAccordion>
        </div>
      </div>
    </div>
  );
}
