import React, { useContext, useState } from "react";
import AuthContext from "../../../context/AuthContext";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { AdminEditUser, GetUsersWithoutPagination } from "../APIs/AdminAPIs";
import Select from "react-select";
export function AdminEditModal({
  open,
  close,
  userId,
  user,
  onUserEdited,
  group,
}) {
  const { contextData, setUserData } = useContext(AuthContext);
  const { authTokens } = contextData;
  const [formData, setFormData] = useState({
    username: user.username || "",
    first_name: user.first_name || "",
    last_name: user.last_name || "",
    email: user.email || "",
    // password: "",
    role: user.role || "",
    yubikey: user?.yubikey || "",
    custom_group:
      user?.custom_group?.map((group) => ({ name: group.name })) || [],
  });
  const [isLoading, setIsLoading] = useState(false);

  const [formErrors, setFormErrors] = useState({
    email: null,
    password: null,
    role: null,
    username: null,
    yubikey: null,
  });

  const userRoles = {
    null: "",
    EMPLOYEE: "EMPLOYEE",
    SUPERVISOR: "SUPERVISOR",
    ADMIN: "ADMIN",
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    // Validate email
    if (!formData.email) {
      errors.email = "This field is required.";
      isValid = false;
    }

    // Validate password
    if (!formData.password) {
      errors.password = "This field is required.";
      isValid = false;
    }

    // Validate role
    if (!formData.role) {
      errors.role = "This field is required.";
      isValid = false;
    }

    // Validate username
    if (!formData.username) {
      errors.username = "This field is required.";
      isValid = false;
    }

    setFormErrors(errors);

    return isValid;
  };

  const handleSaveChanges = async () => {
    if (!validateForm) return;

    setIsLoading(true);

    try {
      // Fetch all users without pagination
      const allUserDataResponse = await GetUsersWithoutPagination(authTokens);

      if (allUserDataResponse.status === 200) {
        const allUserData = allUserDataResponse.data;

        // Check if Yubikey is assigned in any page
        const isYubikeyAssigned = allUserData.some(
          (userData) =>
            userData.yubikey === formData.yubikey && userData.id !== userId
        );

        if (isYubikeyAssigned) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            yubikey: "This Yubikey is already assigned to another user.",
          }));
          setIsLoading(false);
          return;
        }

        // Continue with the save changes logic
        const response = await AdminEditUser(authTokens, userId, formData);

        if (response.status === 200) {
          //console.log("User edited successfully", response.data);
          close();
          setIsLoading(false);
          if (onUserEdited) {
            onUserEdited();
          }
        } else if (response.status !== 200) {
          setIsLoading(false);
          // Handle validation errors
          const errors = response.data;
          //console.log("errors",errors);
          //console.log("errors.username",errors.username);
          setFormErrors({
            email: errors.email ? errors.email[0] : null,
            password: errors.password ? errors.password[0] : null,
            role: errors.role ? errors.role[0] : null,
            username: errors.username ? errors.username[0] : null,
            yubikey: errors.yubikey ? errors.yubikey[0] : null,
          });
          //console.log("formErrors:", formErrors);
        } else {
          console.error("Error editing user", response.data);
        }
      } else {
        console.error("Error getting all users", allUserDataResponse.data);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error editing user", error);
    }
  };

  const handleInputChange = (e) => {
    //console.log("Input changed:", e.target.name, e.target.value);

    const { name, value } = e.target;

    // For all fields, set the value directly
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    // Clear the corresponding error when the field is edited
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
  };

  const handleGroupSelectChange = (selectedOptions) => {
    const selectedGroups = selectedOptions?.map((option) => ({
      name: option.value,
    }));
    setFormData({ ...formData, custom_group: selectedGroups });
    setFormErrors({ ...formErrors, custom_group: null });
  };

  return (
    <>
      <Modal show={open} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Update User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="Editusername">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                name="username"
                onChange={handleInputChange}
                value={formData.username}
              />
              {formErrors.username && (
                <Form.Text className="text-danger">
                  {formErrors.username}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="Editfirst_name">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="first_name"
                onChange={handleInputChange}
                value={formData.first_name}
              />
              {formErrors.first_name && (
                <Form.Text className="text-danger">
                  {formErrors.first_name}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="Editlast_name">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="last_name"
                onChange={handleInputChange}
                value={formData.last_name}
              />
              {formErrors.last_name && (
                <Form.Text className="text-danger">
                  {formErrors.last_name}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="Editemail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                onChange={handleInputChange}
                value={formData.email}
              />
              {formErrors.email && (
                <Form.Text className="text-danger">
                  {formErrors.email}
                </Form.Text>
              )}
            </Form.Group>
            {/* <Form.Group className="mb-3" controlId="Editpassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  onChange={handleInputChange}
                  value={formData.password}
                />
                {formErrors.password && (
                  <Form.Text className="text-danger">
                    {formErrors.password}
                  </Form.Text>
                )}
              </Form.Group> */}
            <Form.Group className="mb-3" controlId="EditroleSelect">
              <Form.Label>Role</Form.Label>
              <Form.Control
                as="select"
                name="role"
                onChange={handleInputChange}
                value={formData.role}
              >
                {Object.values(userRoles)?.map((role) => (
                  <option key={role} value={role}>
                    {role}
                  </option>
                ))}
              </Form.Control>
              {formErrors.role && (
                <Form.Text className="text-danger">{formErrors.role}</Form.Text>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="group">
              <Form.Label>Groups</Form.Label>
              <Select
                isMulti
                name="custom_group"
                options={group?.map((g) => ({ value: g.name, label: g.name }))}
                onChange={handleGroupSelectChange}
                value={formData.custom_group?.map((group) => ({
                  value: group.name,
                  label: group.name,
                }))}
              />
              {formErrors.custom_group && (
                <Form.Text className="text-danger">
                  {formErrors.custom_group}
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="EditYubikey">
              <Form.Label>The Current Yubikey</Form.Label>
              <Form.Control
                type="text"
                name="yubikey"
                onChange={handleInputChange}
                value={formData.yubikey}
              />
              {formErrors.yubikey && (
                <Form.Text className="text-danger">
                  {formErrors.yubikey}
                </Form.Text>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isLoading}
            variant="primary"
            onClick={handleSaveChanges}
          >
            {isLoading ? <div className="loading-spinner" /> : "Save Changes"}
          </Button>
          <Button className="btn-danger" onClick={close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
