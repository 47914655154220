import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../context/AuthContext";
import { AdminAddUser, GetAdminGroup } from "../APIs/AdminAPIs";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";

export function AdminCreateModal({ open, close, onUserSaved, users, group }) {
  const { contextData } = useContext(AuthContext);
  const { authTokens } = contextData;
  const [formData, setFormData] = useState({
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    role: "",
    yubikey: "",
    custom_group: [],
  });

  const [isLoading, setIsLoading] = useState(false);

  const [formErrors, setFormErrors] = useState({
    username: null,
    email: null,
    password: null,
    role: null,
    yubikey: null,
    custom_group: null,
  });

  // handel
  const userRoles = {
    null: "",
    EMPLOYEE: "EMPLOYEE",
    SUPERVISOR: "SUPERVISOR",
    ADMIN: "ADMIN",
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    // Validate email
    if (!formData.email) {
      errors.email = "This field is required.";
      isValid = false;
    }

    // Validate password
    if (!formData.password) {
      errors.password = "This field is required.";
      isValid = false;
    }

    // Validate role
    if (!formData.role) {
      errors.role = "This field is required.";
      isValid = false;
    }

    // Validate username
    if (!formData.username) {
      errors.username = "This field is required.";
      isValid = false;
    }

    setFormErrors(errors);

    return isValid;
  };

  const handleSaveChanges = async () => {
    //console.log("Form Data:", formData);
    if (!validateForm()) return;
    const yubikeyExists = users.some(
      (user) => user.yubikey === formData.yubikey
    );

    if (yubikeyExists) {
      setFormErrors({
        ...formErrors,
        yubikey: "This Yubikey is already assigned to another user.",
      });
      return;
    }
    // const isUserInGroup = group.some(
    //   (g) => g.name === formData.name
    // );

    // if (isUserInGroup) {
    //   setFormErrors({
    //     ...formErrors,
    //     custom_group: "User is already assigned to this group.",
    //   });
    //   return;
    // }
    setIsLoading(true);
    const formattedData = {
      ...formData,
      custom_group: formData.custom_group?.map((group) => ({
        name: group.name,
      })),
    };
    try {
      //console.log("Sending request with data:", formData);
      const response = await AdminAddUser(authTokens, formData);

      if (response.status === 201) {
        //console.log("User created successfully", response.data);
        close();
        setIsLoading(false);
        if (onUserSaved) {
          onUserSaved();
        }
      } else if (response.status !== 201) {
        setIsLoading(false);
        // Handle validation errors
        const errors = response.data;
        setFormErrors({
          email: errors.email ? errors.email[0] : null,
          password: errors.password ? errors.password[0] : null,
          role: errors.role ? errors.role[0] : null,
          username: errors.username ? errors.username[0] : null,
          yubikey: errors.yubikey ? errors.yubikey[0] : null,
        });
      } else {
        console.error("Error creating user", response.data);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error creating user", error);
    }
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;

  //   if (name === "custom_group") {
  //     //console.log("Selected Group:", group.find((g) => g.name === value));
  //     // Check if custom_group is already an array, if yes, add the new value
  //     const updatedCustomGroup = Array.isArray(formData.custom_group)
  //       ? [...formData.custom_group, { name: value }]
  //       : [{ name: value }];

  //     setFormData({ ...formData, custom_group: updatedCustomGroup });
  //   } else if (name === "yubikey") {
  //     setFormData({ ...formData, [name]: { [name]: value } });
  //   } else {
  //     setFormData({ ...formData, [name]: value });
  //   }

  //   setFormErrors({ ...formErrors, [name]: null });
  // };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "yubikey") {
      setFormData({ ...formData, [name]: { [name]: value } });
    } else {
      setFormData({ ...formData, [name]: value });
    }

    setFormErrors({ ...formErrors, [name]: null });
  };

  const handleGroupSelectChange = (selectedOptions) => {
    const selectedGroups = selectedOptions?.map((option) => ({
      name: option.value,
    }));
    setFormData({ ...formData, custom_group: selectedGroups });
    setFormErrors({ ...formErrors, custom_group: null });
  };

  return (
    <>
      <Modal show={open} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Create User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="username">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                name="username"
                onChange={handleInputChange}
                value={formData.username}
              />
              {formErrors.username && (
                <Form.Text className="text-danger">
                  {formErrors.username}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="first_name">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="first_name"
                onChange={handleInputChange}
                value={formData.first_name}
              />
              {formErrors.first_name && (
                <Form.Text className="text-danger">
                  {formErrors.first_name}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="last_name">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="last_name"
                onChange={handleInputChange}
                value={formData.last_name}
              />
              {formErrors.last_name && (
                <Form.Text className="text-danger">
                  {formErrors.last_name}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                onChange={handleInputChange}
                value={formData.email}
              />
              {formErrors.email && (
                <Form.Text className="text-danger">
                  {formErrors.email}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                onChange={handleInputChange}
                value={formData.password}
              />
              {formErrors.password && (
                <Form.Text className="text-danger">
                  {formErrors.password}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="roleSelect">
              <Form.Label>Role</Form.Label>
              <Form.Control
                as="select"
                name="role"
                onChange={handleInputChange}
                value={formData.role}
              >
                {Object.values(userRoles)?.map((role) => (
                  <option key={role} value={role}>
                    {role}
                  </option>
                ))}
              </Form.Control>
              {formErrors.role && (
                <Form.Text className="text-danger">{formErrors.role}</Form.Text>
              )}
            </Form.Group>
            {/* <Form.Group className="mb-3" controlId="group">
              <Form.Label>Group</Form.Label>
              <Form.Control
                as="select"
                name="custom_group"
                onChange={handleInputChange}
                value={formData.custom_group ? formData.custom_group.name : ""}
              >
                <option value="">Assign to group</option>
                {group?.map((g) => (
                  <option key={g.name} value={g.name}>
                    {g.name}
                  </option>
                ))}
              </Form.Control>
              {formErrors.custom_group && (
                <Form.Text className="text-danger">
                  {formErrors.custom_group}
                </Form.Text>
              )}
            </Form.Group> */}
            <Form.Group className="mb-3" controlId="group">
              <Form.Label>Groups</Form.Label>
              <Select
                isMulti
                name="custom_group"
                options={group?.map((g) => ({ value: g.name, label: g.name }))}
                onChange={handleGroupSelectChange}
                value={formData.custom_group?.map((group) => ({
                  value: group.name,
                  label: group.name,
                }))}
              />
              {formErrors.custom_group && (
                <Form.Text className="text-danger">
                  {formErrors.custom_group}
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="yubikey">
              <Form.Label>Yubikey</Form.Label>
              <Form.Control
                type="text"
                name="yubikey"
                onChange={handleInputChange}
                value={formData.yubikey.yubikey}
              />
              {formErrors.yubikey && (
                <Form.Text className="text-danger">
                  {formErrors.yubikey}
                </Form.Text>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isLoading}
            variant="primary"
            onClick={handleSaveChanges}
          >
            {isLoading ? (
              <div className="loading-spinner"></div>
            ) : (
              "Save Changes"
            )}
          </Button>
          <Button className="btn-danger" onClick={close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
