import AuthContext from "../../../context/AuthContext";

import React, { useContext, useState } from "react";
import Card from "react-bootstrap/Card";
import dayjs, { format } from "dayjs";
export default function CustomCard({
  title,
  description,
  date,
  noOfEmployees,
  onEditClick,
  onDeleteClick,
  onAssignClick,
  onViewClick,
}) {
  const { contextData } = useContext(AuthContext);
  const { user } = contextData;
  // const capitalizeFirstLowercaseRest = (str) => {
  //   return str?.charAt(0)?.toUpperCase() + str?.slice(1).toLowerCase();
  // };
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  //console.log(user.role);
  return (
    // <Card style={{ width: "18rem" }} className=" shadow m-1 mx-4">
    <Card className="shadow">
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          {dayjs(date).format("DD-MM-YY")}
        </Card.Subtitle>
        <Card.Subtitle className="mb-2 text-muted">
          {noOfEmployees !== undefined && (
            <Card.Subtitle className="mb-2 text-muted">
              {`Employees : ${noOfEmployees}`}
            </Card.Subtitle>
          )}
        </Card.Subtitle>
        <Card.Text
          // onMouseEnter={handleMouseEnter}
          // onMouseLeave={handleMouseLeave}
          style={{
            maxHeight: "2rem",
            overflow: isHovered ? "visible" : "hidden",
            textOverflow: "ellipsis",
            lineHeight: "1rem",
            whiteSpace: isHovered ? "" : "nowrap",
          }}
        >
          {" "}
          {description}
        </Card.Text>

        {(user?.role === "SUPERVISOR" || user?.role === "ADMIN") && ( // Check if the user is an HR
          <>
            <button className="btn" onClick={onAssignClick}>
              <i className="bi bi-person-plus" style={{ color: "blue" }}></i>
            </button>
            <button className="btn" onClick={onEditClick}>
              <i className="bi bi-pen " style={{ color: "#FABD22" }}></i>{" "}
            </button>
            <button className="btn" onClick={onViewClick}>
              <i className="bi bi-eye" style={{ color: "black" }}></i>
            </button>
            <button className="btn" onClick={onDeleteClick}>
              <i class="bi bi-trash3" style={{ color: "red" }}></i>
            </button>
          </>
        )}
        {user?.role === "EMPLOYEE" && ( // Check if the user is an HR
          <>
            <button className="btn" onClick={onViewClick}>
              <i className="bi bi-eye" style={{ color: "black" }}></i>
            </button>
          </>
        )}
      </Card.Body>
    </Card>
  );
}
