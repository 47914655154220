import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { GetDropDownProjects, addTask } from "../APIs/EmployeeAPIS";
import AuthContext from "../../../context/AuthContext";

export function TaskModal({ open, close, onTaskSaved }) {
  const { contextData } = useContext(AuthContext);
  const { authTokens } = contextData;
  const [assignedProjects, setAssignedProjects] = useState([]);
  const { user } = contextData;
  const [formData, setFormData] = useState({
    project: null,
    name: "",
    description: "",
    user: user,
  });

  const [formErrors, setFormErrors] = useState({
    project: null,
    name: null,
    description: null,
  });
  const validateForm = () => {
    const errors = {};

    // Validate project
    if (!formData.project_id) {
      errors.project = "Please select a project.";
    }

    // Validate task name
    if (!formData.name.trim()) {
      errors.name = "Task name is required.";
    }

    setFormErrors(errors);

    // Return true if there are no errors, indicating the form is valid
    return Object.values(errors).every((error) => !error);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const response = await addTask(authTokens, {
        project: formData.project_id,
        name: formData.name,
        description: formData.description,
        user: formData.user.user_id,
      });

      if (response.status === 201) {
        //console.log("Task created successfully", response.data);
        setFormData({
          project: null,
          name: "",
          description: "",
          user: user,
        });
        close();
        if (onTaskSaved) {
          onTaskSaved();
        }
      } else if (response.status === 400) {
        // Handle validation errors
        const errors = response.data;
        if (
          errors.name &&
          errors.name[0] === "task with this name already exists."
        ) {
          setFormErrors({ ...formErrors, name: errors.name[0] });
        } else {
          console.error("Error creating task", errors);
        }
      } else {
        console.error("Error creating task", response.data);
      }
    } catch (error) {
      console.error("Error creating task", error);
    }
  };

  useEffect(() => {
    GetDropDownProjects(authTokens)
      .then((response) => {
        setAssignedProjects(response.data);
      })
      .catch((error) => {
        console.error("Error getting projects", error);
      });
  }, [authTokens]);

  const handleProjectChange = (e) => {
    setFormData({ ...formData, project_id: e.target.value });
  };

  const handleNameChange = (e) => {
    setFormData({ ...formData, name: e.target.value });
  };

  const handleDescriptionChange = (e) => {
    setFormData({ ...formData, description: e.target.value });
  };

  return (
    <>
      <Modal show={open} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Create Your new Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="projectSelect">
              <Form.Label>Your Projects</Form.Label>
              <Form.Control
                as="select"
                onChange={handleProjectChange}
                isInvalid={!!formErrors.project}
              >
                <option value="" disabled selected>
                  Select a project
                </option>
                {assignedProjects?.map((project) => (
                  <option key={project.id} value={project.id}>
                    {project.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {formErrors.project}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="taskNameInput">
              <Form.Label>Task Name</Form.Label>
              <Form.Control
                type="text"
                onChange={handleNameChange}
                value={formData.name}
                isInvalid={!!formErrors.name}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="taskDescriptionTextarea">
              <Form.Label>Task Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={handleDescriptionChange}
                value={formData.description}
                isInvalid={!!formErrors.description}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.description}
              </Form.Control.Feedback>
            </Form.Group>

            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-danger" onClick={close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
