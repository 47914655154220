import React, { useContext, useState } from "react";
import AuthContext from "../../../context/AuthContext";
import { AdminAddYubikey } from "../APIs/AdminAPIs";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

export function AdminCreateYubikeyModal({ open, close, onUserSaved }) {
  const { contextData } = useContext(AuthContext);
  const { authTokens } = contextData;
  const [formData, setFormData] = useState({
    yubikey: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const [formErrors, setFormErrors] = useState({
    yubikey: null,
  });

  const validateForm = () => {
    const errors = {};
    let isValid = true;
    // Validate yubikey
    if (!formData.yubikey) {
      errors.yubikey = "This field is required.";
      isValid = false;
    }
    setFormErrors(errors);
    return isValid;
  };

  const handleSaveChanges = async () => {
    if (!validateForm()) return;
    //console.log(formData)
    setIsLoading(true);
    try {
      const response = await AdminAddYubikey(authTokens, formData);

      if (response.status === 201) {
        //console.log("Yubikey created successfully", response.data);
        close();

        setIsLoading(false);
        onUserSaved();
      } else if (response.status !== 201) {
        // Handle validation errors
        setIsLoading(false);
        const errors = response.data;
        setFormErrors({
          yubikey: errors.yubikey ? errors.yubikey[0] : null,
        });
      } else {
        console.error("Error creating Yubikey", response.data);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error creating yubikey", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: null });
  };

  return (
    <>
      <Modal show={open} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Create Yubikey</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="yubikey">
              <Form.Label>Yubikey</Form.Label>
              <Form.Control
                type="text"
                name="yubikey"
                onChange={handleInputChange}
                value={formData.yubikey}
              />
              {formErrors.yubikey && (
                <Form.Text className="text-danger">
                  {formErrors.yubikey}
                </Form.Text>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isLoading}
            variant="primary"
            onClick={handleSaveChanges}
          >
            {isLoading ? (
              <div className="loading-spinner"></div>
            ) : (
              "Save Changes"
            )}
          </Button>
          <Button className="btn-danger" onClick={close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
