import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

export default function BaseSearch({onClick, placeholder, setSearchLabel}) {
    const [searchText,setSearchText]=useState('')

   

    const onChange = (e) => {
        e.preventDefault();
        setSearchLabel(e.target.value);
      };

      const onKeyDown = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          onClick(e);
        }
      };

  return (
    // <Container className="bg-white m-3  container">
    <Container className=" m-sm-3 my-3 bg-second">
      <Row>
        <Col sm={4} className="w-100">
          <Form className="d-flex flex-column flex-sm-row">
            <Form.Control
              type="search"
              placeholder={placeholder}
              className="me-2"
              aria-label="Search"
              onChange={(e) => onChange(e)}
              onKeyDown={(e) => onKeyDown(e)}

            />
            <Button onClick={(e)=>onClick(e)} className="btn" style={{backgroundColor:"#4f9f9f" , borderColor:"white"}}>
              Search
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}