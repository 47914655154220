import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import { Login } from "./components/Login/Login";
import { WelcomePage } from "./components/WelcomePage/WelcomePage";
import AuthContext, { AuthProvider } from "./context/AuthContext";
import BaseSideBar from "./components/SideBar/BaseSideBar";
import TestF from "./components/test";
import HRSideBar from "./components/HR/SideBar/HRSideBar";
import BaseProjects from "./components/utils/Components/BaseProjects";
import { Projects } from "./components/Employee/Projects/Projects";
import { Tasks } from "./components/Employee/Tasks/Tasks";
import ProjectTasks from "./components/HR/Components/ProjectTasks";
import EmployeesHR from "./components/HR/Components/EmployeesHR";
import EmplyeeTasks from "./components/HR/Components/EmployeeTasks";
import TimeSheets from "./components/utils/Components/TimeSheets";
import EmployeeTimeSheet from "./components/utils/Components/EmployeeTimeSheet";
import { HRRoute } from "./components/utils/Private Routes/HRRoute";
import MainSideBar from "./components/SideBar/MainSideBar";
import { EmployeeRoute } from "./components/utils/Private Routes/EmployeeRoute";
import { MyTimeSheet } from "./components/Employee/TimeSheets/MyTimeSheet";
import { HRTimeSheet } from "./components/HR/Components/HRTimeSheet";
import AboutPage from "./components/WelcomePage/AboutPage";
import TourPage from "./components/WelcomePage/TourPage";

import { AdminTasks } from "./components/Admin/Tasks/AdminTasks";
import { AdminProject } from "./components/Admin/Projects/AdminProject";
import { Admin, AdminUI, Resource, fetchUtils } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";
import { AdminRoute } from "./components/utils/Private Routes/AdminRoute";
import { GetAllTasks } from "./components/Admin/APIs/AdminAPIs";
import { AdminUser } from "./components/Admin/Users/AdminUser";
import PageNOtFound from "./components/PageNOtFound";

function App() {
  return (
    <div className=" h-100">
      <AuthProvider>
        {/* <MainSideBar></MainSideBar> */}
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/tour" element={<TourPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<PageNOtFound />} />

          {/* HRRoute */}
          <Route exact path="/hr" element={<HRRoute />}>
            <Route path="/hr/projects" element={<BaseProjects />} />
            <Route path="/hr/project-tasks/:id" element={<ProjectTasks />} />
            <Route path="/hr/employees" element={<EmployeesHR />} />
            <Route
              path="/hr/timesheets"
              element={
                <TimeSheets
                  RouteUrlOne="/hr/timesheet-by-date"
                  RouteUrlTwo="/hr/timesheets/employee/"
                />
              }
            />
            <Route
              path="/hr/timesheets/employee/:id"
              element={<EmployeeTimeSheet RouteUrl="/hr/timesheet-by-date/" />}
            />
            <Route path="/hr/timesheet-by-date" element={<HRTimeSheet />} />
            <Route path="/hr/employee-tasks/:id" element={<EmplyeeTasks />} />
            <Route path="/hr/tasks" element={<Tasks />} />
          </Route>

          {/* EmployeeRoute */}
          <Route exact path="/" element={<EmployeeRoute />}>
            <Route path="/test" element={<TestF></TestF>} />
            <Route path="/employee/projects" element={<Projects />} />
            <Route path="/tasks" element={<Tasks />} />
            <Route path="/projects" element={<BaseProjects />} />
            <Route
              path="/employee/project-tasks/:id"
              element={<ProjectTasks />}
            />
            <Route
              path="/employee/timesheets"
              element={
                <TimeSheets
                  RouteUrlOne="/employee/timesheet-by-date"
                  RouteUrlTwo="/employee/timesheets/employee/"
                />
              }
            />{" "}
            <Route
              path="/employee/timesheets/employee/:id"
              element={
                <EmployeeTimeSheet RouteUrl="/employee/timesheet-by-date/" />
              }
            />
            <Route
              path="/employee/timesheet-by-date"
              element={<HRTimeSheet employee="true" />}
            />
          </Route>

          {/* AdminRoute */}
          <Route exact path="/" element={<AdminRoute />}>
            <Route path="/admin/projects" element={<BaseProjects />} />
            <Route path="/hr/projects" element={<BaseProjects />} />
            <Route path="/admin/project-tasks/:id" element={<ProjectTasks />} />
            <Route path="/admin/tasks" element={<AdminTasks />} />
            <Route path="/admin/users" element={<AdminUser />} />
            <Route path="/admin/my-tasks" element={<Tasks />} />
            <Route
              path="/admin/timesheets"
              element={
                <TimeSheets
                  RouteUrlOne="/admin/timesheet-by-date"
                  RouteUrlTwo="/admin/timesheets/employee/"
                />
              }
            />
            <Route
              path="/admin/timesheets/employee/:id"
              element={
                <EmployeeTimeSheet RouteUrl="/admin/timesheet-by-date/" />
              }
            />
            <Route
              path="/admin/timesheet-by-date"
              element={<HRTimeSheet admin="true" />}
            />
          </Route>
        </Routes>
      </AuthProvider>
    </div>
  );
}

export default App;
