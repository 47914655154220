import React from "react";
import BaseSideBar from "../SideBar/BaseSideBar";

export function AdminSideBar() {
  const items = [
    {
      href: "/admin/projects",
      label: "Projects",
      iClass: "bi bi-archive ",
    },
    {
      href: "/admin/tasks",
      label: "Tasks",
      iClass: "bi bi-check2-circle",
    },
    {
      href: "/admin/users",
      label: "Users",
      iClass: "bi bi-people-fill",
    },
    {
      href: "/admin/timesheets",
      label: "Timesheets",
      iClass: "bi bi-clock-history",
    },
    {
      href: "/admin/my-tasks",
      label: "My Tasks",
      iClass: "bi bi-check2-circle",
    },
   
  ];
  return (
    <div>
      <BaseSideBar data={items}></BaseSideBar>
    </div>
  );
}
