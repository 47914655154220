import React from "react";
import Card from "react-bootstrap/Card";
import { Image } from "react-bootstrap";
import personImage from "../../Styles/images/personPng.png";
import "../../Styles/card.css";
export const capitalizeEveryName = (str) => {
  let splitted = str.split(" ");
  splitted = splitted?.map(
    (name) => name.charAt(0).toUpperCase() + name.slice(1)
  );
  return splitted.join(" ");
};
export default function CustomEmployeeCard({
  onEditClick,
  onDeleteClick,
  onAssignClick,
  onViewClick,
  isAdmin,
  userFullName,
  email,
}) {

  return (
    <Card  className=" shadow  " style={{height:'auto'}}>
      <Card.Body>
        <Card.Img
          className="card-g"
          as={Image}
          src={personImage}
          fluid={true}
          alt="Card image"
        />
        
        <Card.Title>{capitalizeEveryName(userFullName)}</Card.Title>

        <Card.Text> {email}</Card.Text>

        <div className="d-flex justify-content-center">
          <button className="btn" onClick={onViewClick}>
            <i class="bi bi-eye" style={{ color: "blue" }}></i>
          </button>
          {isAdmin ? (
            <>
              <button className="btn" onClick={onAssignClick}>
                <i class="bi bi-person-plus" style={{ color: "blue" }}></i>
              </button>
              <button className="btn " onClick={onEditClick}>
                <i class="bi bi-pen " style={{ color: "#FABD22" }}></i>{" "}
              </button>
              <button className="btn" onClick={onDeleteClick}>
                <i class="bi bi-trash3" style={{ color: "red" }}></i>
              </button>
            </>
          ) : null}
        </div>
      </Card.Body>
    </Card>
  );
}
