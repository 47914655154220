import React, { useContext, useState, useEffect } from "react";
import {
  DeleteTask,
  GetAllTasks,
  GetTasksById,
  GetUsers,
} from "../APIs/AdminAPIs";
import AuthContext from "../../../context/AuthContext";
import "../Tasks/spinner.css";

import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { TimeEntryModal } from "./TimeEntryModal";
import "../adminPanel.css";
import { useSafeSetState } from "react-admin";
import { CreateTaskModal } from "./CreateTaskModal";
import { EditTaskModal } from "./EditTaskModal";
import CreateTimeModal from "./CreateTimeModal";

export function AdminTasks() {
  const { contextData } = useContext(AuthContext);
  const { authTokens } = contextData;
  const [tasks, setTasks] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState("");
  const [tasksName, setTasksName] = useState("");
  const [countRows, setCountRows] = useState();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 9,
    page: 0,
  });
  const [IsCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [IsEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editTask, setEditTask] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchTasks();
  }, [paginationModel]);

  const fetchTasks = async () => {
    setIsLoading(true);
    try {
      const response = await GetAllTasks(
        authTokens,
        paginationModel.page + 1,
        paginationModel.pageSize
      );
      setTasks(response.data);
      setCountRows(response.data.length);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  const handleEdit = async (taskId) => {
    try {
      const response = await GetTasksById(
        authTokens,
        taskId,
        paginationModel.page + 1,
        paginationModel.pageSize
      );
      setEditTask(response.data);
      //console.log("task by id", response.data);
    } catch (error) {
      console.error("Error fetching task by id:", error);
    }
    setIsEditModalOpen(true);
  };

  const handleTaskEdited = () => {
    fetchTasks(); // Fetch the updated list of tasks after a new task is created
  };

  // const handleDelete = async (taskId) => {
  //   const isConfirmed = window.confirm(
  //     `Are you sure you want to delete task id: "${taskId}"?`
  //   );

  //   // If user confirms, proceed with deletion
  //   if (isConfirmed) {
  //     setIsLoading(true);
  //     await DeleteTask(authTokens, taskId);

  //     // Fetch the new data after deletion
  //     try {
  //       const response = await GetAllTasks(
  //         authTokens,
  //         paginationModel.page + 1,
  //         paginationModel.pageSize
  //       );

  //       setIsLoading(false);

  //       const remainingTasks = response.data.results || [];

  //       // Update the local state with the new data
  //       setTasks(remainingTasks);

  //       // Check if there are no more tasks on the current page
  //       if (remainingTasks.length === 0 && paginationModel.page > 1) {
  //         // If there are no more tasks on the current page and it's not the first page,
  //         // go back to the previous page
  //         const newPage = paginationModel.page - 1;

  //         // Update the pagination model
  //         setPaginationModel((prevModel) => ({
  //           ...prevModel,
  //           page: newPage,
  //         }));

  //         // Fetch the data for the new page
  //         const updatedResponse = await GetAllTasks(
  //           authTokens,
  //           newPage,
  //           paginationModel.pageSize
  //         );

  //         const updatedTasks = updatedResponse.data.results || [];
  //         setTasks(updatedTasks);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching tasks after deletion:", error);
  //       setIsLoading(false);
  //     }
  //   }
  // };
  const handleDelete = async (taskId) => {
    const isConfirmed = window.confirm(
      `Are you sure you want to delete task id: "${taskId}"?`
    );

    // If user confirms, proceed with deletion
    if (isConfirmed) {
      setIsLoading(true);

      try {
        // Perform the deletion
        await DeleteTask(authTokens, taskId);

        // Remove the deleted task from the local state
        const updatedTasks = tasks.filter((task) => task.id !== taskId);
        setTasks(updatedTasks);

        // Check if there are no more tasks on the current page

        if (updatedTasks.length === 0 && paginationModel.page > 1) {
          setIsLoading(true);
          // If there are no more tasks on the current page and it's not the first page,
          // go back to the previous page
          const newPage = paginationModel.page - 1;

          // Update the pagination model
          setPaginationModel((prevModel) => ({
            ...prevModel,
            page: newPage,
          }));
        }
        setIsLoading(true);
        // Fetch the updated total count of tasks without fetching the tasks themselves
        // Assuming GetAllTasks returns the total count along with the data
        const totalCountResponse = await GetAllTasks(
          authTokens,
          paginationModel.page + 1, // Use the current page for total count
          paginationModel.pageSize
        );

        // Update the total count in the pagination model
        setPaginationModel((prevModel) => ({
          ...prevModel,
          rowCount: totalCountResponse.data.count || 0,
        }));
        setIsLoading(false);
      } catch (error) {
        console.error("Error during deletion:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleEditTimeEntries = async (taskId, taskName) => {
    try {
      const response = await GetTasksById(
        authTokens,
        taskId,
        paginationModel.page + 1,
        paginationModel.pageSize
      );
      setEditTask(response.data); // Set the editTask state with the fetched data
      setId(taskId);
      setTasksName(taskName);
      setOpenModal(true);
      //console.log(`time entry for task with ID: ${taskId}`);
    } catch (error) {
      console.error("Error fetching task by id:", error);
    }
  };

  const handleCreateTask = () => {
    setIsCreateModalOpen(true);
    //console.log("Create Task clicked");
  };
  const handleTaskCreated = () => {
    fetchTasks(); // Fetch the updated list of tasks after a new task is created
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "name", headerName: "Name", width: 110 },
    { field: "description", headerName: "Description", width: 200 },
    {
      field: "project.name",
      headerName: "Project",
      width: 110,
      valueGetter: (params) =>
        params.row.project ? params.row.project.name : "",
    },
    {
      field: "user.email",
      headerName: "Employee",
      width: 150,
      valueGetter: (params) => (params.row.user ? params.row.user.email : ""),
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 120,
      valueGetter: (params) => params.row.created_at,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toISOString().split("T")[0]; // Format as yyyy-mm-dd
      },
    },
    {
      // headerName: "Actions",
      width: 250,
      disableSorting: true,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div>
          <IconButton onClick={() => handleEdit(params.row.id)} color="primary">
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row.id)} color="error">
            <DeleteIcon />
          </IconButton>

          <Button
            style={{ marginLeft: "12px" }}
            variant="outlined"
            onClick={() =>
              handleEditTimeEntries(params.row.id, params.row.name)
            }
          >
            Time Entries
          </Button>
        </div>
      ),
    },
  ];

  const CustomToolbar = () => (
    <div style={{ padding: "10px" }}>
      <GridToolbarContainer>
        <GridToolbarExport />
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleCreateTask}
        style={{ marginTop: "10px" }}
      >
        Create Task
      </Button>
    </div>
  );

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      {isLoading ? (
        <div className="loading-spinner-container">
          <div className="loading-spinner"></div>
        </div>
      ) : (
        <DataGrid
          rows={tasks || []}
          columns={columns}
          autoHeight={false}
          disableSelectionOnClick
          onSelectionModelChange
          disableRowSelectionOnClick
          slots={{
            toolbar: CustomToolbar,
          }}
          pagination
          rowCount={countRows}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          paginationMode="client"
        />
      )}
      {openModal && (
        <TimeEntryModal
          // setTasks={setTasks}
          open={openModal}
          close={() => {
            setOpenModal(false);
          }}
          taskId={id}
          nameTask={tasksName}
          data={editTask}
        />
      )}
      {IsCreateModalOpen && (
        <CreateTaskModal
          open={IsCreateModalOpen}
          close={() => {
            setIsCreateModalOpen(false);
          }}
          tasks={tasks}
          onTaskCreated={handleTaskCreated}
        />
      )}
      {IsEditModalOpen && (
        <EditTaskModal
          open={IsEditModalOpen}
          close={() => {
            setIsEditModalOpen(false);
          }}
          tasks={tasks}
          onTaskEdit={handleTaskEdited}
          data={editTask}
        />
      )}
      <CreateTimeModal />
    </div>
  );
}
