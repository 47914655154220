import React from "react";
import "../Styles/sidebar.css";
import colors from "../../resources/colors";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
export default function BaseSideBar({ data }) {
  const { contextData } = useContext(AuthContext);
  const { user } = contextData;
  //console.log(user);

  const handleLogout = () => {
    contextData.logOut();
  };
  //console.log(user.name.split[0]);

  const pathname = useLocation().pathname;
  return (
    <div
      className="container-fluid float-start col-3  "
      style={{ height: "100vh", margin: "0px", padding: "0px", width: "24%" }}
      id="main"
    >
      <div className="row flex-nowrap h-100 sticky-sidebar">
        <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0  w-100 sidebar-body">
          <div
            style={{ justifyContent: "space-around" }}
            className="d-flex flex-column  align-items-center align-items-md-start px-3 pt-2 text-white w-100  min-vh-100"
          >
            <Link
              to="/"
              className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none"
            >
              <span className="fs-4 d-none d-md-inline">Menu</span>
            </Link>
            <ul
              className="nav nav-pills flex-column mb-md-auto mb-0 align-items-center align-items-md-start"
              id="menu"
            >
              {data?.map((item, index) => (
                <li className="nav-item py-2" key={index}>
                  <Link
                    to={item.href}
                    className={`nav-link align-middle px-0 ${
                      item.href === pathname ? "active " : ""
                    }`}
                  >
                    <i className={item.iClass + " icons"}></i>{" "}
                    <span className="ms-1 d-none d-md-inline">
                      {item.label}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
            <div className="dropdown pb-2">
              <i class="bi bi-person-check-fill"></i>{" "}
              <span className=" d-none d-md-inline mx-1">
                Logged In - {user ? user.email : ""}
              </span>
            </div>

            <div class="dropdown pb-4">
              <Link
                to="/login"
                class="d-flex align-items-center text-white text-decoration-none "
                id="dropdownUser1"
                onClick={handleLogout}
              >
                <i class="bi bi-arrow-left-square"></i>{" "}
                <span class="d-none d-md-inline mx-1 px-1"> Log Out</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
