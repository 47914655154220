import React from "react";
import Select from "react-select";

export default function BaseSelectWithSearch({
  values,
  onChange,
  name,
  value,
  selected,
  placeholder,
  styles,
  className,
  required,
  optionShowing,
  isMulti,
}) {
  const options = values?.map((option, index) => ({
    value: optionShowing ? option.value : option,
    label: optionShowing ? option.name : option,
  }));

  return (
    <div>
      <Select
        className={` ${selected ? selected : ""} ${className} h-50`}
        options={options}
        onChange={onChange}
        value={value}
        styles={styles}
        placeholder={placeholder}
        isSearchable={true}
        required={required ? required : false}
        maxMenuHeight={"18vh"}
        name={name}
        isMulti={isMulti ? isMulti : false}
      />
    </div>
  );
}
