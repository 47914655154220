import React from "react";
import "../../Styles/pageStyles.css";

export default function HeaderDiv({ title, className }) {
  let todayDate = new Date();
  const options = {
    weekday: "long",
    day: "numeric",
    month: "numeric",
    year: "numeric",
  };
  const formattedDate = todayDate.toLocaleDateString("en-US", options);

  return (
    <div
      style={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      className={`d-flex justify-content-between p-4 ${className}`}
    >
      <h3>{title}</h3>
      <div>
        <h5 className="bg-white">
          {formattedDate.replace("/", "-").replace("/", "-").replace(",", " ")}
        </h5>
      </div>
    </div>
  );
}
