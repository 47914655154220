import React, { useContext, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "../../resources/font.css";
import { Image, Nav, Navbar } from "react-bootstrap";
import "../../resources/colors";
import colors from "../../resources/colors";
import "../WelcomePage/burgermenu.css";
import AuthContext from "../../context/AuthContext";
import { Button } from "@mui/material";
// import body from "../../imgs/WelcomePage/body2.webp";
export default function WelcomeNavbar() {
  let { contextData } = useContext(AuthContext);
  let { user, logOut } = contextData || {};

  //console.log(user);
  return (
    <Navbar
      collapseOnSelect
      expand="md"
      style={{ backgroundColor: colors.bg_nav }}
    >
      <Link
        to="/"
        style={{
          color: "white",
          padding: "15px",
          marginLeft: "20px",
          cursor: "pointer",
          textDecoration: "none",
        }}
      >
        <h4 style={{ margin: "0" }}>Task Tracker</h4>
      </Link>
      <Navbar.Toggle
        style={{ marginRight: "10px" }}
        aria-controls="responsive-navbar-nav"
      />
      <Navbar.Collapse
        style={{ justifyContent: "center" }}
        id="responsive-navbar-nav"
      >
        <Nav
          className="me-auto"
          style={{ display: "flex", alignItems: "center" }}
        >
          <NavLink
            style={{
              color: "white",
              padding: "0 10px",
              textDecoration: "none",
            }}
            to="/tour"
          >
            Tuotrial
          </NavLink>
          <NavLink
            style={{
              color: "white",
              padding: "0 10px",
              margin: "8px",
              textDecoration: "none",
            }}
            to="/about"
          >
            About us
          </NavLink>
        </Nav>
        <Nav style={{ display: "flex", alignItems: "center" }}>
          {user === null ? (
            <Link
              style={{
                color: "white",
                padding: "5px 30px",
                backgroundColor: colors.bg_button,
                borderRadius: "15px",
                textDecoration: "none",
              }}
              to="/login"
              className="login-button"
            >
              Login
            </Link>
          ) : (
            <>
              <Link
                to={
                  user.role === "SUPERVISOR"
                    ? "/hr/projects"
                    : user.role === "EMPLOYEE"
                    ? "/tasks"
                    : user.role === "ADMIN" && "/admin/projects"
                  // : "null"
                }
                style={{
                  color: "white",
                  padding: "5px 30px",
                  backgroundColor: colors.bg_button,
                  borderRadius: "15px",
                  textDecoration: "none",
                  // marginRight:'5px'
                }}
                className="link-role"
              >
                {user.role}
              </Link>
              <a
                href="/login"
                style={{
                  color: "white",
                  padding: "5px 30px",
                  backgroundColor: "red",
                  borderRadius: "15px",
                  textDecoration: "none",
                }}
                className="login-button"
                onClick={logOut}
              >
                Log out
              </a>
            </>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
