import React, { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import HRSideBar from "../HR/SideBar/HRSideBar";
import { EmployeeSideBar } from "../Employee/EmployeeSideBar";
import { AdminSideBar } from "../Admin/AdminSideBar";

export default function MainSideBar() {
  let { contextData } = useContext(AuthContext);
  let { user } = contextData || {};

  if (user?.role === "SUPERVISOR") {
    return <HRSideBar />;
  }
  if (user?.role === "EMPLOYEE") {
    return <EmployeeSideBar />;
  }

  if (user?.role === "ADMIN") {
    return <AdminSideBar />;
  } else {
    return;
  }
}