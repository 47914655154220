// import React, { useEffect, useState } from "react";
// import HeaderDiv from "./HeaderDiv";
// import BaseSelectWithSearch from "./BaseSelectWithSearch";
// import { getAllEmployees } from "../APIs/coreApis";
// import BaseYearSelector from "./BaseYearSelector";
// import { useNavigate } from "react-router-dom";

// export default function TimeSheets({ RouteUrlOne, RouteUrlTwo }) {
//   const navigator = useNavigate();
//   const [employees, setEmployees] = useState([]);
//   const [selectedEmployee, setSelectedEmployee] = useState([]);
//   // const [selectedEmployee, setSelectedEmployee] = useState({
//   //   value: employees[0].id,
//   //   label: employees[0].full_name,
//   // });
//   const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
//   const [showView, setShowView] = useState(false);

//   const handleYearChange = (e) => {
//     setSelectedYear(e.target.value);
//   };

//   useEffect(() => {
//     getAllEmployees().then((employees) => {
//       //console.log(employees);
//       setEmployees(employees);
//     });
//   }, []);

//   const handleSelectChange = (e) => {
//     setSelectedEmployee(e);
//     setShowView(true);
//     //console.log(selectedEmployee);
//   };

//   const onViewClick = () => {
//     navigator(`${RouteUrlTwo}${selectedEmployee.value}`, {
//       state: selectedEmployee,
//     });
//     setShowView(false);
//   };

//   const onYearClick = () => {
//     navigator(`${RouteUrlOne}`, {
//       state: { selectedYear: selectedYear },
//     });
//   };
//   //console.log();
//   return (
//     <div className="d-flex" style={{ height: "100vh" }}>
//       <div className=" container">
//         <HeaderDiv title="Timesheets"></HeaderDiv>
//         <div className="d-flex justify-content-around my-5 align-content-start ">
//           <div className=" row ">
//             <h5 className="text-center">View by Employee</h5>
//             <BaseSelectWithSearch
//               className={" my-3 w-75 mx-auto"}
//               optionShowing={true}
//               onChange={handleSelectChange}
//               values={employees?.map((employee) => {
//                 return { value: employee.id, name: employee.full_name };
//               })}
//             ></BaseSelectWithSearch>
//             {showView && (
//               <button
//                 className="btn btn-success w-75 m-auto"
//                 onClick={onViewClick}
//               >
//                 View
//               </button>
//             )}
//           </div>
//           <div className=" row">
//             <h5 className="text-center">View by Date</h5>
//             <BaseYearSelector
//               className={"my-3 p-1"}
//               selectedYear={selectedYear}
//               onChange={handleYearChange}
//             ></BaseYearSelector>
//             <button className="btn btn-success  m-auto" onClick={onYearClick}>
//               View
//             </button>
//           </div>
//           {/* <div className=" row ">
//             <h5 className="text-center">View by Yubikey</h5>
//             <BaseSelectWithSearch
//               className={" my-3 w-75 mx-auto"}
//               optionShowing={true}
//               onChange={handleSelectChange}
//               values={employees?.map((employee) => {
//                 return { value: employee.id, name: employee.full_name };
//               })}
//             ></BaseSelectWithSearch>
//             <button
//               className="btn btn-success w-75 m-auto"
//               onClick={onViewClick}
//             >
//               View
//             </button>
//           </div> */}
//         </div>
//       </div>
//     </div>
//   );
// }
import React, { useContext, useEffect, useState } from "react";
import HeaderDiv from "./HeaderDiv";
import BaseSelectWithSearch from "./BaseSelectWithSearch";
import { getAllEmployees } from "../APIs/coreApis";
import BaseYearSelector from "./BaseYearSelector";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../context/AuthContext";

export default function TimeSheets({ RouteUrlOne, RouteUrlTwo }) {
  const navigator = useNavigate();
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [showView, setShowView] = useState(false);
  const { contextData } = useContext(AuthContext);
  const { user } = contextData;

  useEffect(() => {
    getAllEmployees().then((employees) => {
      setEmployees(employees);
    });
  }, []);

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleSelectChange = (e) => {
    setSelectedEmployee(e);
    setShowView(true);
  };

  const onViewClick = () => {
    navigator(`${RouteUrlTwo}${selectedEmployee.value}`, {
      state: selectedEmployee,
    });
    setShowView(false);
  };

  const onYearClick = () => {
    navigator(`${RouteUrlOne}`, {
      state: { selectedYear: selectedYear },
    });
  };

  // Filter employees based on role
  const currentUser = user;
  //console.log(currentUser);
  const supervisors = employees?.filter(
    (employee) =>
      employee?.role === "SUPERVISOR" && currentUser.user_id != employee.id
  );
  const admins = employees?.filter(
    (employee) =>
      employee?.role === "ADMIN" && currentUser.user_id != employee.id
  );
  const otherEmployees = employees?.filter(
    (employee) =>
      employee?.role === "EMPLOYEE" && currentUser.user_id != employee.id
  );

  return (
    <div className="d-flex" style={{ height: "100vh" }}>
      <div className="container">
        <HeaderDiv title="Timesheets"></HeaderDiv>
        <div className="d-flex flex-column justify-content-around my-5 align-content-start ">
          <div className="row mb-5">
            <h5 className="text-center mb-2">View My TimeSheet</h5>
            <div className="col-md-6 mx-auto">
              <BaseSelectWithSearch
                className={"w-100"}
                optionShowing={true}
                onChange={handleSelectChange}
                values={[
                  { value: currentUser.user_id, name: currentUser.name },
                ]}
              ></BaseSelectWithSearch>
              {showView && (
                <button
                  className="btn btn-success w-100 mt-2"
                  onClick={onViewClick}
                >
                  View
                </button>
              )}
            </div>
          </div>
          {(currentUser.role === "ADMIN" ||
            currentUser.role === "SUPERVISOR") && (
            <div className="row mb-5">
              <h5 className="text-center mb-2">View by Supervisors</h5>
              <div className="col-md-6 mx-auto">
                <BaseSelectWithSearch
                  className={"w-100"}
                  optionShowing={true}
                  onChange={handleSelectChange}
                  values={supervisors?.map((supervisor) => ({
                    value: supervisor.id,
                    name: supervisor.full_name,
                  }))}
                ></BaseSelectWithSearch>
                {showView && (
                  <button
                    className="btn btn-success w-100 mt-2"
                    onClick={onViewClick}
                  >
                    View
                  </button>
                )}
              </div>
            </div>
          )}

          {currentUser.role == "ADMIN" && (
            <div className="row mb-5">
              <h5 className="text-center mb-2">View by Admins</h5>
              <div className="col-md-6 mx-auto">
                <BaseSelectWithSearch
                  className={"w-100"}
                  optionShowing={true}
                  onChange={handleSelectChange}
                  values={admins?.map((admin) => ({
                    value: admin.id,
                    name: admin.full_name,
                  }))}
                ></BaseSelectWithSearch>
                {showView && (
                  <button
                    className="btn btn-success w-100 mt-2"
                    onClick={onViewClick}
                  >
                    View
                  </button>
                )}
              </div>
            </div>
          )}
          {(currentUser.role === "ADMIN" ||
            currentUser.role === "SUPERVISOR") && (
            <div className="row mb-5">
              <h5 className="text-center mb-2">View by Employees</h5>
              <div className="col-md-6 mx-auto">
                <BaseSelectWithSearch
                  className={"w-100"}
                  optionShowing={true}
                  onChange={handleSelectChange}
                  values={otherEmployees?.map((employee) => ({
                    value: employee.id,
                    name: employee.full_name,
                  }))}
                ></BaseSelectWithSearch>
                {showView && (
                  <button
                    className="btn btn-success w-100 mt-2"
                    onClick={onViewClick}
                  >
                    View
                  </button>
                )}
              </div>
            </div>
          )}
          {(currentUser.role === "ADMIN" ||
            currentUser.role === "SUPERVISOR") && (
            <div className="row mb-3">
              <h5 className="text-center mb-2">View by Date</h5>
              <div className="col-md-6 mx-auto">
                <BaseYearSelector
                  className={"w-100 p-1"}
                  selectedYear={selectedYear}
                  onChange={handleYearChange}
                ></BaseYearSelector>
                <button
                  className="btn btn-success w-100 mt-2"
                  onClick={onYearClick}
                >
                  View
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
